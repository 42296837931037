import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_GET, AXIOS_PATCH, AXIOS_POST, AXIOS_DELETE } from './service/apiService';
import { toastr } from 'react-redux-toastr';

interface ProjectModule {
  projectModule: any[],
  page: number,
  limit: number,
  totalCount: number,
  formConfig: any,
  formvalue: {
    modulename: string,
    modulediscription: string,
    role: any,
    status: boolean,
    formPayload: any[],
    public: boolean,
    iframeUrl: any,
    type: any,
    formUrlSuffix: string,
    project: any
  },
  roleList: any[],
  roleListTotalCount: number,
  moduleId: number,
  loader: boolean,
  deleteRecId: any,
  // suffixForm: any,
  suffixFormConfig: any,
  suffixFormValue: any,
  projectModuleId: number,
  projectModuleName: string,
  clone: boolean
}

const initialFormValueState = {
  modulename: '', modulediscription: '', role: '', status: false, formPayload: [], public: false, iframeUrl: '', type: '', formUrlSuffix: '', project: ''
}

const initialState: ProjectModule = {
  projectModule: [],
  page: 1,
  limit: 10,
  totalCount: 0,
  formConfig: null,
  formvalue: { modulename: '', modulediscription: '', role: [], status: false, formPayload: [], public: false, iframeUrl: '', type: '', formUrlSuffix: '', project: '' },
  roleList: [],
  roleListTotalCount: 0,
  moduleId: 0,
  loader: false,
  deleteRecId: null,
  suffixFormConfig: null,
  suffixFormValue: null,
  projectModuleId: 0,
  projectModuleName: "",
  clone: false
};

const slice = createSlice({
  name: 'ProjectModule',
  initialState,
  reducers: {
    getJobList(state: ProjectModule, action: PayloadAction<{ moduleData: any[], totalCount: number }>) {
      // const { job } = action.payload;
      state.projectModule = action.payload.moduleData;
      state.totalCount = action.payload.totalCount
    },

    getModuleData(state: ProjectModule, action: PayloadAction<{ jobData: any }>) {
      // const { job } = action.payload;
      console.log("ProjectSave", action.payload, action.payload.jobData)
      state.formConfig = action.payload.jobData.formPayload;
      state.moduleId = action.payload.jobData.id
    },

    getModuleDetails(state: ProjectModule, action: PayloadAction<{ formvalue: any }>) {
      console.log("responses==============>", action.payload.formvalue)
      state.formvalue.modulename = action.payload.formvalue.moduleName
      state.formvalue.modulediscription = action.payload.formvalue.moduleDescription
      state.formvalue.status = action.payload.formvalue.status
      state.formvalue.public = action.payload.formvalue.public
      state.formvalue.iframeUrl = action.payload.formvalue.iframeUrl
      state.formvalue.type = action.payload.formvalue.type
      state.formvalue.formUrlSuffix = action.payload.formvalue.formUrlSuffix
      state.formvalue.project = action.payload.formvalue.project

      state.formvalue.formPayload = action.payload.formvalue.formPayload
      // state.formvalue.role = action.payload.formvalue.roles[0].id
      state.formConfig = action.payload.formvalue.formPayload

    },
    getRoleList(state: ProjectModule, action: PayloadAction<{ roleList: any, totalCount: number }>) {
      state.roleList = action.payload.roleList
      state.roleListTotalCount = action.payload.totalCount
    },
    resetForm(state: ProjectModule) {
      state.formvalue = initialFormValueState
    },
    loader(state: ProjectModule, action: PayloadAction<any>) {
      state.loader = action.payload.loader
    },
    updateModuleList(state: ProjectModule, action: PayloadAction<{ id: any, list: any[] }>) {
      console.log('UPDATE MODULE')
      let filteredArray = action.payload.list.filter(item => item.id != action.payload.id)
      state.projectModule = filteredArray
    },
    setDeleteRecordId(state: ProjectModule, action: PayloadAction<{ deleteRecId: string | number }>) {
      state.deleteRecId = action.payload.deleteRecId
    },
    setSuffixFormConfig(state: ProjectModule, action: PayloadAction<{ suffixForm: any }>) {
      // state.suffixForm = action.payload.suffixForm
      state.suffixFormConfig = action.payload.suffixForm.formPayload
      state.suffixFormValue = action.payload.suffixForm.formPayloadValue
      state.projectModuleId = action.payload.suffixForm.moduleId
      state.projectModuleName = action.payload.suffixForm.moduleName

    },
    setSuffixFormValueAlone(state: ProjectModule, action: PayloadAction<{ formValue: any }>) {
      // state.suffixForm = action.payload.suffixForm
      state.suffixFormValue = action.payload.formValue
    }
  }
});

export const reducer = slice.reducer;

export const getProjectModuleList = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loader({ loader: true }))
  const response: any = await AXIOS_GET(`/api/project-module/1?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

  dispatch(slice.actions.getJobList({ moduleData: response.data.list, totalCount: response.data.totalCount }));
  dispatch(slice.actions.loader({ loader: false }))
};

export const saveProjectModule = (payload, history): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_POST('/api/project-module', payload, {});

  if (response?.data?.id) {
    toastr.success('Success', response?.message);
    history.replace(`/app/module/update/${response.data.id}`);
    dispatch(slice.actions.getModuleData({ jobData: response.data }));
  } else {
    toastr.error('Error', response?.message);
  }
};

export const getProjectModuleById = (id): AppThunk => async (dispatch) => {
  try {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET('/api/project-module/findone/' + id, {}, {});

    if (response) {
      dispatch(slice.actions.getModuleDetails({ formvalue: response?.data }));
    }
  } catch (err) {
    toastr.error('Error', err);

  } finally {
    dispatch(slice.actions.loader({ loader: false }));
  }
};

export const updateProjectModule = (id, payload, history): AppThunk => async (dispatch) => {
  try {
    dispatch(slice.actions.loader({ loader: true }))
    const response: any = await AXIOS_PATCH('/api/project-module/' + id, payload, {});

    if (response) {
      toastr.success('Success', response?.message);
      history.push(`/app/module`);
      // dispatch(slice.actions.getModuleData({ jobData: response }));
    } else {
      toastr.error('Error', response?.message);
    }
  } catch (err) {
    toastr.error('Error', err);
  } finally {
    dispatch(slice.actions.loader({ loader: false }));

  }
};

export const getRoles = (): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/role', {}, {});
  console.log("responses", response)
  if (response) {
    dispatch(slice.actions.getRoleList({ roleList: response.data, totalCount: response.totalCount }));
  }
};

export const formvaluerefresh = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetForm());
};

export const deleteModuleById = (datalist, id): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_DELETE('/api/project-module/delete/' + id, {});
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

  if (response?.data?.isDeleted) {
    toastr.success('Success', response?.message);
    // dispatch(slice.actions.updateModuleList({ id :  id, list : datalist}));
  } else {
    toastr.error('Error', response?.message);
  }

  return response?.data?.isDeleted == true ? true : false;
};

/* #region form url suffix */
export const getFormByUrlSuffix = (formUrlSuffix): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET('/api/project-module/form/' + formUrlSuffix, {}, {});

  dispatch(slice.actions.setSuffixFormConfig({
    suffixForm: response?.data || null
  }));

};

export const getFormByUrlSuffixAndUUId = (formUrlSuffix, uuid): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_GET(`/api/project-module/form/${formUrlSuffix}/${uuid}`, {}, {});

  dispatch(slice.actions.setSuffixFormConfig({
    suffixForm: response?.data || null
  }));

};

export const saveUrlSuffixForm = (payload: any): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_POST('/api/project-module-value/form', payload, {});

  if (response?.status) {
    toastr.success('Success', response?.message);
  } else {
    toastr.error('Error', response?.message);
  }

  return response?.status == true ? true : false;
};

export const updateUrlSuffixForm = (id: string, payload: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.loader({ loader: true }))

  const response: any = await AXIOS_PATCH('/api/project-module-value/form/' + id, payload, {});

  if (response?.status) {
    dispatch(slice.actions.setSuffixFormValueAlone({ formValue: response?.data?.formPayload || null }))
    toastr.success('Success', response?.message);
  } else {
    toastr.error('Error', response?.message);
  }
  dispatch(slice.actions.loader({ loader: false }))

  return response?.status == true ? true : false;
};


export const cloneProjectModel = (payload, history): AppThunk => async (dispatch) => {
  const response: any = await AXIOS_POST('/api/project-module/clone', payload, {});
  console.log('===== response ', response)
  if (response?.data?.id) {
    toastr.success('Success', response?.message);
    history.replace(`/app/module/update/${response.data.id}`);
    dispatch(slice.actions.getModuleDetails({ formvalue: response.data }));
  } else {
    toastr.error('Error', response?.message);
  }
};


/* #endregion form url suffix */

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
  dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}
/* #endregion custom functions */

export default slice;
