import axios, { AxiosError } from "axios";

// export const BASE_URL = 'http://172.20.8.210:3200';
// export const BASE_URL = 'http://172.20.6.50:3400';
// export const BASE_URL = 'http://172.20.6.56:3400';
// export const BASE_URL = 'http://172.20.6.158:3400';
// export const BASE_URL = 'http://localhost:3200';
// export const BASE_URL = 'http://172.20.8.138:3200';
// export const BASE_URL = 'http://172.20.8.75:3200';
// export const BASE_URL = 'http://172.20.6.18:3400';
   export const BASE_URL = 'https://api-lms.wait-wise.com';
// export const BASE_URL = 'http://172.20.6.43:3400';
// export const BASE_URL = 'http://172.20.6.17:3400';
// export const BASE_URL = 'http://csat.kgis.co';

const getToken = () => {
    const innertoken = localStorage.getItem('accessToken')
    const token: string = "Bearer " + innertoken
    return token
}

const clearToken = () => {
    localStorage.removeItem('accessToken');
    document.location.href = '/login';
}

export const AXIOS_POST = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'POST',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });

        return response.data;
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response;
        } else {
            return null
        }
    }
}

export const AXIOS_FORM_POST = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'POST',
            url: BASE_URL + url,
            headers: {
                ...headers,
                authorization: getToken()
            },
            data: body
        });
        return response.data;
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }
            return error.response;
        } else {
            return null
        }
    }
}

export const AXIOS_GET = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'GET',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            params: body
        });
        return response.data
    } catch (error) {
        if (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
            }

            return error.response
        } else {
            return null
        }
    }
}


export const AXIOS_PATCH = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'PATCH',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}
export const AXIOS_PUT = async (url: string, body: any, headers: any) => {
    try {
        const response = await axios({
            method: 'PUT',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
            data: body
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}
export const AXIOS_DELETE = async (url: string, headers: any) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: BASE_URL + url,
            headers: {
                authorization: getToken()
            },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            clearToken();
        }

        return error.response.data;
    }
}