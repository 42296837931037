import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';
import { CourseStateData } from 'src/types/course';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: CourseStateData = {
    courseList: [],
    courseTotalCount: 0,
    selectedCourseObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false,
    courseFormType: 'Add'
};

const slice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setCourseList(state: CourseStateData, action: PayloadAction<{ courseList: any[], courseTotalCount: number, clear: boolean }>) {
            const currentCourseList: any[] = current(state).courseList;
            state.courseTotalCount = action.payload.courseTotalCount;

            if (action.payload.clear) {
                state.courseList = action.payload.courseList;
            } else {
                state.courseList = [...currentCourseList, ...action.payload.courseList];
            }
        },
        setSelectedCourseObject(state: CourseStateData, action: PayloadAction<{ selectedCourseObject: any }>) {
            state.selectedCourseObject = action.payload.selectedCourseObject
        },
        setModalOpenOrCloseStatus(state: CourseStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: CourseStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: CourseStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setCourseFormType(state: CourseStateData, action: PayloadAction<{ courseFormType: string }>) {
            state.courseFormType = action.payload.courseFormType
        },
        
    }
});

export const reducer = slice.reducer;

export const getCourseList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/course?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setCourseList({
        courseList: response?.data && response?.data.length ? response?.data[0] : [],
        courseTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const getBatchList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/batch?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let batchList: any[] = [];
    let batchTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        batchTotalCount = response?.data[1] || 0;

        batchList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }
    
    return { batchList: batchList || [], batchTotalCount: batchTotalCount }
};

export const getSessionList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/_all?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let sessionList: any[] = [];
    let sessionTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionTotalCount = response?.data[1] || 0;

        sessionList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }
    
    return { sessionList: sessionList || [], sessionTotalCount: sessionTotalCount }
};

export const createCourse = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/course', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success' ));
        dispatch(slice.actions.setSelectedCourseObject({ selectedCourseObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error' ));
    }

    return response?.data?.id ? true : false;
};

export const getCourseById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/course/get/' + id, {}, {});

    dispatch(slice.actions.setSelectedCourseObject({ selectedCourseObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteCourseById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/course/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, snackbarGlobConfig('success' ));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error' ));
    }

  return response?.status == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const setCourseFormType = (courseFormType: string = 'Add') => (dispatch) => {
    dispatch(slice.actions.setCourseFormType({ courseFormType: courseFormType }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedCourseObject({ selectedCourseObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
