import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { SessionStateData } from 'src/types/session';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: SessionStateData = {
    sessionList: [],
    sessionTotalCount: 0,
    selectedSessionObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false,
    creationLoader: false,
    userSessionList : [],
    usersessionTotalCount : 0,
    singleuserSessionData : null,
    sessionId: 0,
    sessionIdStatics : 0,
    sessionTypeName: null,
    SessionTypeId : 0,
    popStatusData:null,
    popQuestionData:null
};

const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionList(state: SessionStateData, action: PayloadAction<{ sessionList: any[], sessionTotalCount: number, clear: boolean }>) {
            const currentSessionList = current(state).sessionList;
            state.sessionTotalCount = action.payload.sessionTotalCount;

            if (action.payload.clear) {
                state.sessionList = action.payload.sessionList;
            } else {
                state.sessionList = [...currentSessionList, ...action.payload.sessionList];
            }
        },
        setUserSessionList(state: SessionStateData, action: PayloadAction<{ userSessionList: any[], usersessionTotalCount: number, clear: boolean }>){
            const currentSessionList = current(state).userSessionList;
            state.usersessionTotalCount = action.payload.usersessionTotalCount;

            if (action.payload.clear) {
                state.userSessionList = action.payload.userSessionList;
            } else {
                state.userSessionList = [...currentSessionList, ...action.payload.userSessionList];
            }
        },
        setSelectedSessionObject(state: SessionStateData, action: PayloadAction<{ selectedSessionObject: any }>) {
            state.selectedSessionObject = action.payload.selectedSessionObject
        },
        setModalOpenOrCloseStatus(state: SessionStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: SessionStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: SessionStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setCreationLoader(state: SessionStateData, action: PayloadAction<any>) {
            state.creationLoader = action.payload.creationLoader
        },
        setSingleUserSession (state: SessionStateData, action: PayloadAction<any>) {
            state.singleuserSessionData = action.payload.selectedsession
        },
        // -*-*-*-*-*-*. function to set popup close status globally -*-*-*-*-*-*.
        setPopupStatus (state: SessionStateData, action: PayloadAction<any>) {
            state.popStatusData = action.payload.receivedAction
               
        },
         // -*-*-*-*-*-*. function to set popup QuestionInfo globally -*-*-*-*-*-*.
        setPopupQuestionInfo (state: SessionStateData, action: PayloadAction<any>) {
            state.popQuestionData = action.payload.receivedAction
            
        },
        setSessionId(state: SessionStateData, action: PayloadAction<{id,typename,typeId}>){
            state.sessionId = action.payload.id
            state.sessionTypeName = action.payload.typename
            state.SessionTypeId = action.payload.typeId
        },
        setSessionIdForStatics(state: SessionStateData, action: PayloadAction<any>){
            state.sessionIdStatics = action.payload.id
        }

       
    }
});

export const reducer = slice.reducer;

export const getSessionList = (page: number = 0, limit: number = 10, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/session/_all?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setSessionList({
        sessionList: response?.data && response?.data.length ? response?.data[0] : [],
        sessionTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};
// *********** function to make api call to get the overall course completion reports **********
export const getCourseCompletionDetails = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {

    const response: any = await AXIOS_GET(`/api/session/search-student-log?page=${page + 1}&limit=${10}&searchKey=${'thamarai'}&_all=false`, {}, {});
    return response;
};
// *********** function to make api call to get student list**********
export const getStudentsList = (limit: number = 10, searchKey: string = '',page: number = 10): AppThunk => async (dispatch) => {

    const response: any = await AXIOS_GET(`/api/users/studentData/getAll?limit=${limit}&skip=${page}&searchKey=${searchKey}`, {}, {});
    return response;

};

// *********** function to make api call to get course list for a student**********
export const getCourseListOfStudent = (studentId:number): AppThunk => async (dispatch) => {

    const response: any = await AXIOS_GET(`/api/course/byStudent/${studentId}`, {}, {});
    return response;

};

export const getUserSessionList = (page: number = 0, limit: number = 10, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    // ?page=1&limit=10&search=
    const response: any = await AXIOS_GET(`/api/session/all?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    // console.log('USER SESSION response',response.data)

    dispatch(slice.actions.setUserSessionList({
        userSessionList: response?.data && response?.data.length ? response?.data : [],
        usersessionTotalCount: response?.totalCount  ? response?.totalCount : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const getSessionTypeListByType = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', type: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session-type?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}&type=${type}`, {}, {});

    let sessionTypeList: any[] = [];
    let sessionTypeTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionTypeTotalCount = response?.data[1] || 0;

        sessionTypeList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }
    
    return { sessionTypeList: sessionTypeList || [], sessionTypeTotalCount: sessionTypeTotalCount }
};

export const getSessionById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/session/' + id, {}, {});

    dispatch(slice.actions.setSelectedSessionObject({ selectedSessionObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const createSession = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/session', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success' ));
            dispatch(slice.actions.setSelectedSessionObject({ selectedSessionObject: null }));
            dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error' ));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error' ));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const getSessionTypeNameList = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session-type?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    let sessionTypeNameList: any[] = [];
    let sessionTypeNameTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionTypeNameTotalCount = response?.data[1] || 0;

        sessionTypeNameList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }
    
    return { sessionTypeNameList: sessionTypeNameList || [], sessionTypeNameTotalCount: sessionTypeNameTotalCount }
};

export const getSessionNameList = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    let sessionNameList: any[] = [];
    let sessionNameTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionNameTotalCount = response?.data[1] || 0;

        sessionNameList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }
    
    return { sessionNameList: sessionNameList || [], sessionNameTotalCount: sessionNameTotalCount }
};

export const deleteSessionById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/session/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, snackbarGlobConfig('success' ));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error' ));
    }

  return response?.status == true ? true : false;

};

export const setSessionId = (id, typename, typeId) : AppThunk => async (dispatch) => { 
    dispatch(slice.actions.setSessionId({ id : id, typename : typename, typeId : typeId }))
}

export const setSessionIdForStatics = (id) : AppThunk => async (dispatch) => { 
    dispatch(slice.actions.setSessionIdForStatics({ id : id }))
} 

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedSessionObject({ selectedSessionObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}

export const setSingleUserSession = (selectedUsersession) => (dispatch) => {
    dispatch(slice.actions.setSingleUserSession({ selectedsession :selectedUsersession })); 
}
export const setPopupStatus = (receivedAction) => (dispatch) => {
    dispatch(slice.actions.setPopupStatus({ receivedAction :receivedAction })); 
     
    
}
export const setPopupQuestionInfo = (receivedAction) => (dispatch) => {
    dispatch(slice.actions.setPopupQuestionInfo({ receivedAction :receivedAction })); 
    
    
}
/* #endregion custom functions */

export default slice;
