import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Avatar, Typography } from '@material-ui/core';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const [role, setRole] = useState('');

  useEffect(() => {
    let userDetails = localStorage.getItem('userDetails');
    let userData;
    if (userDetails != 'undefined') {
      userData = JSON.parse(userDetails)
    }
    setRole(userData?.role?.name)
    console.log(role, "[[[")
  }, []);
 
  return (
    <div>
      {role != 'Student' &&
      <a href="/">
        <img src='/static/KgInvicta.png' alt='Kgisl' style={{ height: '6vh' }}></img>
      </a>
      }
      {role == 'Student' &&
      <a href="/app/employeecourse">
        <img src='/static/KgInvicta.png' alt='Kgisl' style={{ height: '6vh' }}></img>
      </a>
      }

      {/* <a href="/"><img src='/static/kgisl-logo.png' alt='Kgisl' style={{height: '2vh'}}></img></a> */}

      {/* <img src='/static/KgInvicta.png' alt='Kgisl' style={{height: '6vh'}}></img> */}

      {/* <Typography variant="h6" component="h6">
        Desk.
      </Typography> */}

    </div>
    // <img
    //   alt="Logo"
    //   src="/static/logo.svg"
    //   {...props}
    // />
  );
}

export default Logo;
