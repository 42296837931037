import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as JobQueueReducer } from 'src/slices/jobqueue';
import { reducer as ProjectModuleReducer } from 'src/slices/projectModule';
import { reducer as projectsReducer } from 'src/slices/projects';
import { reducer as userReducer } from 'src/slices/users';
import { reducer as roleReducer } from 'src/slices/roles';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as NavItems } from 'src/slices/navbar';
import { reducer as DynamicModule } from 'src/slices/dynamicModule';
import { reducer as AuthModule } from 'src/slices/auth';
import { reducer as AdminModule } from 'src/slices/adminModule';
import { reducer as LeadReducer } from 'src/slices/leads';
import { reducer as ContactReducer } from 'src/slices/contacts';
import { reducer as KnowledgeBaseReducer } from 'src/slices/knowledgeBase';
import { reducer as KanbanTaskReducer } from 'src/slices/kanbanTask';
import { reducer as QAReducer } from 'src/slices/QA';
import { reducer as BatchReducer } from 'src/slices/batch';
import { reducer as CourseReducer } from 'src/slices/course';
import { reducer as EmployeeCourseReducer } from 'src/slices/employeeCourse';
import { reducer as SessionReducer } from 'src/slices/session';
import { reducer as JoiningRequestReducer } from 'src/slices/joiningRequest';
import {reducer as studentModuleReducer} from 'src/slices/studentModule';
import { reducer as gradeReducer } from 'src/slices/grade';
import { reducer as notesReducer } from 'src/slices/notes'


const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  jobQueue: JobQueueReducer,
  projectModule: ProjectModuleReducer,
  navItems: NavItems,
  projects: projectsReducer,
  users: userReducer,
  roles: roleReducer,
  toastr: toastrReducer,
  dynamicmodule: DynamicModule,
  auth: AuthModule,
  adminModule: AdminModule,
  leads: LeadReducer,
  contacts: ContactReducer,
  knowledgeBase: KnowledgeBaseReducer,
  kanbanTask: KanbanTaskReducer,
  QA: QAReducer,
  batch: BatchReducer,
  course: CourseReducer,
  employeeCourse: EmployeeCourseReducer,
  session: SessionReducer,
  JoiningRequest: JoiningRequestReducer,
  studentModule : studentModuleReducer,
  grade: gradeReducer,
  notes: notesReducer
});

export default rootReducer;
