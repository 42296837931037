import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';
import { JoiningRequestStateData } from 'src/types/joiningRequest';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: JoiningRequestStateData = {
    joiningRequestList: [],
    joiningRequestTotalCount: 0,
    selectedJoiningRequestObject: null,
    loader: false,
    approveLoader: false,
    rejectionLoader: false,
    deleteRecId: null,
    isModalOpen: false,
    approveOrRejectionRecId: null
};

const slice = createSlice({
    name: 'joiningRequest',
    initialState,
    reducers: {
        setJoiningRequestList(state: JoiningRequestStateData, action: PayloadAction<{ joiningRequestList: any[], joiningRequestTotalCount: number, clear: boolean }>) {
            const currentJoiningRequestList = current(state).joiningRequestList;
            state.joiningRequestTotalCount = action.payload.joiningRequestTotalCount;

            if (action.payload.clear) {
                state.joiningRequestList = action.payload.joiningRequestList;
            } else {
                state.joiningRequestList = [...currentJoiningRequestList, ...action.payload.joiningRequestList];
            }
        },
        setSelectedJoiningRequestObject(state: JoiningRequestStateData, action: PayloadAction<{ selectedJoiningRequestObject: any }>) {
            state.selectedJoiningRequestObject = action.payload.selectedJoiningRequestObject
        },
        setModalOpenOrCloseStatus(state: JoiningRequestStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: JoiningRequestStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader;
        },
        setApproveLoader(state: JoiningRequestStateData, action: PayloadAction<{approveLoader: boolean, approveOrRejectionRecId: number | string}>) {
            state.approveOrRejectionRecId = action.payload.approveOrRejectionRecId;
            state.approveLoader = action.payload.approveLoader;
        },
        setRejectionLoader(state: JoiningRequestStateData, action: PayloadAction<{rejectionLoader: boolean, approveOrRejectionRecId: number | string}>) {
            state.rejectionLoader = action.payload.rejectionLoader;
            state.approveOrRejectionRecId = action.payload.approveOrRejectionRecId;
        },
        setDeleteRecordId(state: JoiningRequestStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
    }
});

export const reducer = slice.reducer;

export const getJoiningRequestList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/batch/request/get?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    dispatch(slice.actions.setJoiningRequestList({
        joiningRequestList: response?.data && response?.data.length ? response?.data[0] : [],
        joiningRequestTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const getUserList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/users?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let userList: any[] = [];
    let userTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data];
        userTotalCount = response?.totalCount || 0;

        userList = currentList.map(element => {
            return { label: element?.fullName || '', value: element?.id || null }
        });
    }
    
    return { userList: userList || [], userTotalCount: userTotalCount }
};

export const getCourseList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/course?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let courseList: any[] = [];
    let courseTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        courseTotalCount = response?.data[1] || 0;

        courseList = currentList.map(element => {
            return { label: element?.courseName || '', value: element?.id || null }
        });
    }
    
    return { courseList: courseList || [], courseTotalCount: courseTotalCount }
};

export const createJoiningRequest = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/joiningRequest', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig ('success' ));
        dispatch(slice.actions.setSelectedJoiningRequestObject({ selectedJoiningRequestObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig ('error' ));
    }

    return response?.data?.id ? true : false;
};

export const approveOrRejectJoiningRequest = (action: boolean = false, requestId: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {
        if (action) {
            dispatch(slice.actions.setApproveLoader({ approveLoader: true, approveOrRejectionRecId: requestId }));
        } else {
            dispatch(slice.actions.setRejectionLoader({ rejectionLoader: true, approveOrRejectionRecId: requestId }));
        }

        const response: any = await AXIOS_GET(`/api/batch/request/approveReject?action=${action}&requestId=${requestId}`, {}, {});

        if (response?.data == true) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data == true ? true : false;

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error') );
        return false;

    } finally {
        if (action) {
            dispatch(slice.actions.setApproveLoader({ approveLoader: false, approveOrRejectionRecId: null }));
        } else {
            dispatch(slice.actions.setRejectionLoader({ rejectionLoader: false, approveOrRejectionRecId: null }));
        }
    }
};

export const getJoiningRequestById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/joiningRequest/' + id, {}, {});

    dispatch(slice.actions.setSelectedJoiningRequestObject({ selectedJoiningRequestObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteJoiningRequestById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/joiningRequest/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, snackbarGlobConfig ('success' ));
    } else {
        snackBar(response?.message, snackbarGlobConfig ('error' ));
    }

  return response?.status == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedJoiningRequestObject({ selectedJoiningRequestObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
