import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_POST } from './service/apiService';

interface Student {
    studentList :  any[],
    studentsStaticsList : any [],
    studentSessionStaticsxAxis : any[],
    studentSessionStaticsyAxis : any[],
    singleStudentDetails : any,
    randomColors: any,
    studentsReport: any[]
  }

const initialState: Student = {
    studentList : [],
    studentsStaticsList : [],
    studentSessionStaticsxAxis : [],
    studentSessionStaticsyAxis : [],
    singleStudentDetails : null,
    randomColors: [],
    studentsReport: []
 }

 const slice = createSlice({
    name: 'studentModule',
    initialState,
    reducers: { 
        setStudentDetails(state : Student, action :PayloadAction<{details : any}>){
           state.studentList = action.payload.details
           console.log('studentList', state.studentList);
        },
        clearStudentDetials(state : Student, action :PayloadAction<{}>){
            state.studentList = []
        },
        setStudentsStaticsData(state : Student, action :PayloadAction<{details : any, testName:string}>){

            let marks = []
            let users =action.payload.details[0].userSession.map(x => {
                if(x){
                    marks.push(x.score)
                    return x.user ? x.user.firstName + x.user.lastName : null;
                }
            })
            // let Course_Name = []
            // let Session_Name = []
            // let User_Name = []
            // let report =action.payload.details[0].map(x => {
            //     if(x){
            //         Course_Name.push(x.name)
            //         Session_Name.push(x.sessionTypeTest.name)
            //         marks.push(x.userSession.score)
            //         User_Name.push(x.user ? x.user.firstName + x.user.lastName : null)
            //         return {};
            //     }
            // })

            // console.log('1234567890',marks)

            state.studentSessionStaticsxAxis = users
            state.studentSessionStaticsyAxis = marks
            state.studentsStaticsList =  [{details:action.payload.details},{testName:action.payload.testName}]
            console.log('studentsStaticsList', state.studentsStaticsList);

            state.studentsReport = [state.studentSessionStaticsxAxis, state.studentSessionStaticsyAxis]
            console.log('studentsReport', state.studentsReport);
            
        },
        clearStudentsStaticsData(state : Student, action :PayloadAction<{ }>){
            state.studentSessionStaticsxAxis = []
            state.studentSessionStaticsyAxis = []
            state.studentsStaticsList =  []
        },
        setSingleStudentData(state : Student, action :PayloadAction<{data : any}>){
            state.singleStudentDetails = action.payload.data
        }
    } })

export const reducer = slice.reducer;

export const setStudentDetails= (selectedsession_id, searchKey, test) => async (dispatch) => {
    console.log("test...", test)
    const response: any = await AXIOS_GET(`/api/session/get-all-user?sessionId=${selectedsession_id}&searchKey=${searchKey}&sessionTypeId=${test.id}`, {}, {});
    console.log('Student Response',response)
    dispatch(slice.actions.setStudentDetails({details : response?.data}))
}

export const setPopupStudentDetails= (selectedsession_id, searchKey, test, type='') => async (dispatch) => {
    console.log("test...", test)
    const response: any = await AXIOS_GET(`/api/session/get-all-user?sessionId=${selectedsession_id}&searchKey=${searchKey}&sessionTypeId=${test.id}&action=${type}`, {}, {});
    console.log('Student Response',response)
    dispatch(slice.actions.setStudentDetails({details : response?.data}))
}

export const setStudentStaticsData= (selectedsession_id,  typeName, test) => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/get-chart-data?sessionId=${selectedsession_id}&sessionTypeId=${test.id}&sessionType=${typeName}`, {}, {});
   
    dispatch(slice.actions.setStudentsStaticsData({details : response?.data, testName:test.name}))
}

export const clearStudentDetials= () => (dispatch) => {
    dispatch(clearStudentDetials())
}

export const clearStudentsStaticsData = () => (dispatch) =>{
    dispatch(clearStudentsStaticsData())
}

export const setSingleStudentData = (data) => (dispatch) => {
    console.log(data)
    dispatch(setSingleStudentData({data : data}))
}
export default slice;
