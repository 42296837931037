import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction, current } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_FORM_POST, AXIOS_GET, AXIOS_POST } from './service/apiService';
import { QAStateData } from 'src/types/QA';
import _ from 'lodash';
import { snackbarGlobConfig } from 'src/globalConfig';
import { Console } from 'console';

const initialState: QAStateData = {
    departmentList: [],
    departmentTotalCount: 0,
    skillList: [],
    skillTotalCount: 0,
    categorizedTopicDetails: {},
    questionList: [],
    questionTotalCount: 0,
    publishedQuestionCount: 0,
    publishedSubjectiveQuestionCount: 0,
    openedAccordionSkillId: null,
    creationLoader: false,
    topicObjForQuesCreation: null,
    questionTypeMasterList: [],
    difficultyLevelMasterList: [],
    sessionTypeModalOpenStatus: false,
    sessionTypeQuestionList: [],
    userSessionQAList: [],
    singleUserSessionQAList: [],
    sessionSelected: {},
    reports: null,
    studentUserSessionId: null,
    subjectiveQuestionList: [],
    subjectivequestionTotalCount: 0,
};

const slice = createSlice({
    name: 'knowledgeBase',
    initialState,
    reducers: {

        setDepartmentList(state: QAStateData, action: PayloadAction<{ departmentList: any[], departmentTotalCount: number }>) {
            state.departmentList = action.payload.departmentList;
            state.departmentTotalCount = action.payload.departmentTotalCount;
        },
        setSkillList(state: QAStateData, action: PayloadAction<{ skillList: any[], skillTotalCount: number, clear: boolean }>) {
            const currentSkillList = current(state).skillList;
            state.skillTotalCount = action.payload.skillTotalCount;

            if (action.payload.clear) {
                state.skillList = action.payload.skillList;
            } else {
                state.skillList = [...currentSkillList, ...action.payload.skillList];
            }
        },
        setTopicList(state: QAStateData, action: PayloadAction<{ topicList: any[], topicTotalCount: number, skillId: number | string, clear: boolean }>) {
            let skillId = action.payload.skillId;
            let currentCategorizedTopicDetails: any = { ...current(state).categorizedTopicDetails };
            let topicCatDetail = { ...currentCategorizedTopicDetails[skillId] };
            let topicList: any[] = topicCatDetail?.topicList || [];

            if (action.payload.clear) {
                topicList = [...action.payload.topicList];
            } else {
                topicList = [...topicList, ...action.payload.topicList]
            }

            topicCatDetail.topicList = [...topicList]
            topicCatDetail.topicTotalCount = action.payload.topicTotalCount;
            currentCategorizedTopicDetails[skillId] = { ...topicCatDetail };
            state.categorizedTopicDetails = { ...currentCategorizedTopicDetails };
        },
        setQuestionList(state: QAStateData, action: PayloadAction<{ questionList: any[], questionTotalCount: number, clear: boolean }>) {
            
            let currentQuestionList: any[] = [...current(state).questionList];
            let newQuestionList: any[] = [...action.payload.questionList];
            let modifiedQuestionList: any[] = newQuestionList.length && newQuestionList.map(item => { return { ...item, isEditable: false, isExpanded: false } }) || [];
            state.questionTotalCount = action.payload.questionTotalCount;
            // state.publishedQuestionCount = action.payload.publishedQuestionCount;

            if (action.payload.clear) {
                state.questionList = [...modifiedQuestionList];
            } else {
                state.questionList = [...currentQuestionList, ...modifiedQuestionList];
            }
            //  console.log(state.questionList, "state.questionList909090 ");
            let published = 0;
            if (state.questionList.length > 0) {
                state.questionList?.map(element => {
                    if (element.isPublished) {
                        published = published + 1;
                    }
                })
                // console.log(published, "$$ publishedQuestionCount%%")
                state.publishedQuestionCount = published;
            }
        },
        setSubQuestionList(state: QAStateData, action: PayloadAction<{ questionList: any[], questionTotalCount: number, clear: boolean }>) {
            let currentQuestionList: any[] = [...current(state).subjectiveQuestionList];
            let newQuestionList: any[] = [...action.payload.questionList];
            let modifiedQuestionList: any[] = newQuestionList.length && newQuestionList.map(item => { return { ...item, isEditable: false, isExpanded: false } }) || [];
            state.subjectivequestionTotalCount = action.payload.questionTotalCount;
            // state.publishedQuestionCount = action.payload.publishedQuestionCount;

            if (action.payload.clear) {
                state.subjectiveQuestionList = [...modifiedQuestionList];
            } else {
                state.subjectiveQuestionList = [...currentQuestionList, ...modifiedQuestionList];
            }
            //   console.log(state.subjectiveQuestionList, "state.subjectiveQuestionList  343434");
            let published = 0;
            if (state.subjectiveQuestionList.length > 0) {
                state.subjectiveQuestionList?.map(element => {
                    if (element.isPublished) {
                        published = published + 1;
                    }
                })
                console.log(published, "$$ publishedQuestionCount%%")
                state.publishedSubjectiveQuestionCount = published;
            }
        },
        setQuestionEditable(state: QAStateData, action: PayloadAction<{ questionId: any, editableStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).questionList];
            let incomeQuestionId: any = action.payload.questionId;
            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item,
                    isEditable: (incomeQuestionId == item.id) ? action.payload.editableStatus : item?.isEditable || false
                }
            }) || [];

            state.questionList = [...modifiedQuestionArray];
        },
        setSubjectiveQuestionEditable(state: QAStateData, action: PayloadAction<{ questionId: any, editableStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).subjectiveQuestionList];
            let incomeQuestionId: any = action.payload.questionId;
            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item,
                    isEditable: (incomeQuestionId == item.id) ? action.payload.editableStatus : item?.isEditable || false
                }
            }) || [];

            state.subjectiveQuestionList = [...modifiedQuestionArray];
        },
        setQuestionExpanded(state: QAStateData, action: PayloadAction<{ questionId: any, expandedStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).questionList];
            let incomeQuestionId: any = action.payload.questionId;
            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item,
                    isExpanded: (incomeQuestionId == item.id) ? action.payload.expandedStatus : item?.isExpanded || false
                }
            }) || [];

            state.questionList = [...modifiedQuestionArray];
        },
        setSubjectiveQuestionExpanded(state: QAStateData, action: PayloadAction<{ questionId: any, expandedStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).subjectiveQuestionList];
            let incomeQuestionId: any = action.payload.questionId;
            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item,
                    isExpanded: (incomeQuestionId == item.id) ? action.payload.expandedStatus : item?.isExpanded || false
                }
            }) || [];

            state.subjectiveQuestionList = [...modifiedQuestionArray];
        },
        setUserSessionQuestionExpanded(state: QAStateData, action: PayloadAction<{ questionId: any, expandedStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).userSessionQAList];
            let incomeQuestionId: any = action.payload.questionId;
            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item,
                    isExpanded: (incomeQuestionId == item.id) ? action.payload.expandedStatus : item?.isExpanded || false
                }
            }) || [];

            state.userSessionQAList = [...modifiedQuestionArray];
        },
        setAllQuestionExpanded(state: QAStateData, action: PayloadAction<{ expandedStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).questionList];

            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item, isExpanded: action.payload.expandedStatus
                }
            }) || [];

            state.questionList = [...modifiedQuestionArray];

            let currentSubjectiveQuestionList: any[] = [...current(state).subjectiveQuestionList];

            let modifiedSubjectiveQuestionArray: any[] = currentSubjectiveQuestionList.length && currentSubjectiveQuestionList.map(item => {
                return {
                    ...item, isExpanded: action.payload.expandedStatus
                }
            }) || [];

            state.subjectiveQuestionList = [...modifiedSubjectiveQuestionArray];

            

        },
        setUserSessionQuestionsExpanded(state: QAStateData, action: PayloadAction<{ expandedStatus: boolean }>) {
            let currentQuestionList: any[] = [...current(state).userSessionQAList];

            let modifiedQuestionArray: any[] = currentQuestionList.length && currentQuestionList.map(item => {
                return {
                    ...item, isExpanded: action.payload.expandedStatus
                }
            }) || [];

            state.userSessionQAList = [...modifiedQuestionArray];
 
        },
       
        updateQuestionFromQuestionList(state: QAStateData, action: PayloadAction<{ questionObject: any }>) {
            console.log(action.payload.questionObject.questionType,'!!!QQQQQ');
            
            
            let incomeQuestionObject: any = { ...action.payload.questionObject };
            let questionType = action.payload?.questionObject?.questionType;
            let modifiedQuestionArray: any[] = [];

            if (questionType == 'Subjective') {
                let currentQuestionList: any[] = [...current(state).subjectiveQuestionList];
                modifiedQuestionArray = currentQuestionList.length && currentQuestionList.map(item => {
                    if (item.id == incomeQuestionObject?.id) {
                        return { ...incomeQuestionObject, isEditable: false }
                    }
    
                    return item;
                }) || [];
                state.subjectiveQuestionList = [...modifiedQuestionArray];

                let publishedSub = 0;
                if (state.subjectiveQuestionList.length > 0) {
                    state.subjectiveQuestionList?.map(element => {
                        if (element.isPublished) {
                            publishedSub = publishedSub + 1;
                        }
                    })
                    // console.log(publishedSub, "$$ publishedQuestionCount%%")
                    state.publishedSubjectiveQuestionCount = publishedSub;
                }

               
            }else{
                let currentQuestionList: any[] = [...current(state).questionList];
                 modifiedQuestionArray = currentQuestionList.length && currentQuestionList.map(item => {
                    if (item.id == incomeQuestionObject?.id) {
                        return { ...incomeQuestionObject, isEditable: false }
                    }
    
                    return item;
                }) || [];
                state.questionList = [...modifiedQuestionArray];
                let published = 0;
                if (state.questionList.length > 0) {
                    state.questionList?.map(element => {
                        if (element.isPublished) {
                            published = published + 1;
                        }
                    })
                    console.log(published, "$$ publishedQuestionCount%%")
                    state.publishedQuestionCount = published;
                }
            }
  
        },
        appendNewDepartmentItemIntoDepartmentList(state: QAStateData, action: PayloadAction<{ departmentObject: any }>) {
            let currentDepartmentList: any[] = [...current(state).departmentList];
            let currentDepartmentTotalCount: number = current(state).departmentTotalCount;
            let currentDepartmentObject: any = { ...action.payload.departmentObject };

            let filteredDepartmentArray: any[] = currentDepartmentList.length && currentDepartmentList.filter(item => item.value != currentDepartmentObject?.id) || []
            let finalDepartmentArray: any[] = [...filteredDepartmentArray, {
                label: currentDepartmentObject?.name || '',
                value: currentDepartmentObject?.id || null,
                description: currentDepartmentObject?.description || null,
                createdAt: currentDepartmentObject?.createdAt || '',
                createdBy: currentDepartmentObject?.createdBy?.fullName || '',
            }];

            state.departmentList = [...finalDepartmentArray];
            state.departmentTotalCount = (currentDepartmentList.length == finalDepartmentArray.length) ? currentDepartmentTotalCount : (currentDepartmentTotalCount + 1);
        },
        appendNewSkillItemIntoSkillList(state: QAStateData, action: PayloadAction<{ skillObject: any }>) {
            let currentSkillList: any[] = [...current(state).skillList];
            let currentSkillTotalCount: number = current(state).skillTotalCount;
            let currentSkillObject: any = { ...action.payload.skillObject };
            let filteredSkillArray: any[] = currentSkillList.length && currentSkillList.filter(item => item.id != currentSkillObject?.id) || [];
            let finalSkillArray: any[] = [...filteredSkillArray, currentSkillObject];
            state.skillList = [...finalSkillArray];
            state.skillTotalCount = (currentSkillList.length == finalSkillArray.length) ? currentSkillTotalCount : (currentSkillTotalCount + 1);
        },
        appendNewTopicItemIntoTopicList(state: QAStateData, action: PayloadAction<{ topicObject: any, skillId: number }>) {
            let currentCategorizedTopicDetails: any = { ...current(state).categorizedTopicDetails };
            let topicCatDetail = { ...currentCategorizedTopicDetails[Number(action.payload.skillId)] };
            topicCatDetail.topicList = [...topicCatDetail.topicList, action.payload.topicObject];
            topicCatDetail.topicTotalCount = currentCategorizedTopicDetails[Number(action.payload.skillId)].topicTotalCount + 1;
            currentCategorizedTopicDetails[Number(action.payload.skillId)] = { ...topicCatDetail };
            state.categorizedTopicDetails = currentCategorizedTopicDetails;
        },
        removeDepartmentFromListById(state: QAStateData, action: PayloadAction<{ departmentId: string | number }>) {
            let currentDepartmentList: any[] = [...current(state).departmentList];
            let currentDepartmentTotalCount: number = current(state).departmentTotalCount;
            let finalDepartmentArray: any = [];

            if (currentDepartmentList && currentDepartmentList.length) {
                currentDepartmentList.forEach(element => {
                    if (element?.value != action.payload.departmentId) {
                        finalDepartmentArray.push(element);
                    }
                });
            }
            state.departmentList = [...finalDepartmentArray];
            state.departmentTotalCount = (currentDepartmentTotalCount - 1);
        },
        removeSkillFromListById(state: QAStateData, action: PayloadAction<{ skillId: string | number }>) {
            let currentSkillList: any[] = [...current(state).skillList];
            let currentSkillTotalCount: number = current(state).skillTotalCount;
            let filteredSkillArray: any[] = currentSkillList.length && currentSkillList.filter(item => item.id != action.payload.skillId) || []
            state.skillList = [...filteredSkillArray];
            state.skillTotalCount = (currentSkillTotalCount - 1);
        },
        removeTopicFromCategorizedTopicListById(state: QAStateData, action: PayloadAction<{ skillId: string | number, topicId: string | number }>) {
            let currentCategorizedTopicDetails: any = { ...current(state).categorizedTopicDetails };
            let topicCatDetail: any = { ...currentCategorizedTopicDetails[Number(action.payload.skillId)] };
            let filteredTaskArray: any[] = (topicCatDetail.topicList).length && (topicCatDetail.topicList).filter(item => item.id != action.payload.topicId) || [];
            topicCatDetail.topicList = [...filteredTaskArray];
            topicCatDetail.topicTotalCount = currentCategorizedTopicDetails[Number(action.payload.skillId)].topicTotalCount - 1;
            currentCategorizedTopicDetails[Number(action.payload.skillId)] = { ...topicCatDetail };
            state.categorizedTopicDetails = currentCategorizedTopicDetails;
        },
        removeQuestionFromListById(state: QAStateData, action: PayloadAction<{ questionId: string | number }>) {
            let currentQuestionList: any[] = [...current(state).questionList];
            let currentQuestionTotalCount: number = current(state).questionTotalCount;
            let filteredQuestionArray: any[] = currentQuestionList.length && currentQuestionList.filter(item => item.id != action.payload.questionId) || []
            state.questionList = [...filteredQuestionArray];
            state.questionTotalCount = (currentQuestionTotalCount - 1);
            let published = 0;
            if (state.questionList.length > 0) {
                state.questionList?.map(element => {
                    if (element.isPublished) {
                        published = published + 1;
                    }
                })
                // console.log(published, "$$ publishedQuestionCount%%")
                state.publishedQuestionCount = published;
            }
        },
        removeSubQuestionFromListById(state: QAStateData, action: PayloadAction<{ questionId: string | number }>) {
            let currentQuestionList: any[] = [...current(state).subjectiveQuestionList];
            let currentQuestionTotalCount: number = current(state).subjectivequestionTotalCount;
            let filteredQuestionArray: any[] = currentQuestionList.length && currentQuestionList.filter(item => item.id != action.payload.questionId) || []
            state.subjectiveQuestionList = [...filteredQuestionArray];
            state.subjectivequestionTotalCount = (currentQuestionTotalCount - 1);
            let published = 0;
            if (state.subjectiveQuestionList.length > 0) {
                state.subjectiveQuestionList?.map(element => {
                    if (element.isPublished) {
                        published = published + 1;
                    }
                })
                // console.log(published, "$$ publishedQuestionCount%%")
                state.publishedSubjectiveQuestionCount = published;
            }
        },
        setCreationLoader(state: QAStateData, action: PayloadAction<any>) {
            state.creationLoader = action.payload.creationLoader
        },
        setOpenedAccordionSkillId(state: QAStateData, action: PayloadAction<any>) {
            state.openedAccordionSkillId = action.payload.openedAccordionSkillId
        },
        setTopicObjForQuesCreation(state: QAStateData, action: PayloadAction<any>) {
            state.topicObjForQuesCreation = action.payload.topicObjForQuesCreation;
        },
        setQuestionMaster(state: QAStateData, action: PayloadAction<{ questionTypeMasterList: any[], difficultyLevelMasterList: any[] }>) {
            state.questionTypeMasterList = action.payload.questionTypeMasterList;
            state.difficultyLevelMasterList = action.payload.difficultyLevelMasterList;
        },
        setSessionTypeModalOpenStatus(state: QAStateData, action: PayloadAction<{ sessionTypeModalOpenStatus: boolean }>) {
            state.sessionTypeModalOpenStatus = action.payload.sessionTypeModalOpenStatus;
        },
        setSelectedQuestionList(state: QAStateData, action: PayloadAction<{ questionObject: any }>) {
            state.sessionSelected = action.payload.questionObject;
            console.log(state.sessionSelected, '+++++++++')
        },
        setReports(state: QAStateData, action: PayloadAction<{ data: any }>) {
            state.reports = action.payload.data;
            console.log(state.reports, '+23232323232323')
        },

        // ************ function to add the given question object to the question list ************

        setSessionTypeQuestionList(state: QAStateData, action: PayloadAction<{ questionObject: any }>) {
            let currentSessionTypeQuestionList: any[] = [...current(state).sessionTypeQuestionList];
            let incomeQuestionObject: any = { ...action.payload.questionObject };
            let modifiedQuestionArray: any[] = [];

            if (currentSessionTypeQuestionList.length) {
                let alreadyQuesExists: boolean = currentSessionTypeQuestionList.find((x) => (x.id == incomeQuestionObject?.id));

                if (alreadyQuesExists) {
                    modifiedQuestionArray = currentSessionTypeQuestionList.map(item => {
                        if (item.id == incomeQuestionObject?.id) {
                            if (incomeQuestionObject?.isPublished) {
                                return { ...incomeQuestionObject }
                            }
                        }
                        return item;
                    }) || [];
                } else {
                    modifiedQuestionArray = [...currentSessionTypeQuestionList, { ...incomeQuestionObject }];
                }
            } else {
                modifiedQuestionArray.push(incomeQuestionObject);
            }

            state.sessionTypeQuestionList = [...modifiedQuestionArray];
        },
        reInitializeSessionTypeQuestionList(state: QAStateData, action: PayloadAction<{ questionList: any[] }>) {
            state.sessionTypeQuestionList = [...action.payload.questionList];
        },
        removeQuestionFromSessionTypeListById(state: QAStateData, action: PayloadAction<{ questionId: string | number }>) {
            let currentSessionTypeQuestionList: any[] = [...current(state).sessionTypeQuestionList];
            let filteredSessionTypeQuestionArray: any[] = currentSessionTypeQuestionList.length && currentSessionTypeQuestionList.filter(item => item.id != action.payload.questionId) || []
            state.sessionTypeQuestionList = [...filteredSessionTypeQuestionArray];
        },
        clearQAStore(state: QAStateData, action: PayloadAction<{}>) {
            state.categorizedTopicDetails = {};
            state.questionList = [];
            state.questionTotalCount = 0;
            state.openedAccordionSkillId = null;
            state.topicObjForQuesCreation = null;
            state.sessionTypeModalOpenStatus = false;
            state.sessionTypeQuestionList = [];
        },
        setQAForUser(state: QAStateData, action: PayloadAction<any>) {
            //state.userSessionQAList = action.payload.data
            let newQuestionList: any[] = [...action.payload.data];
            state.userSessionQAList = newQuestionList.map(item => ({ ...item, isExpanded: false }));
        },
        setQAForSingleUser(state: QAStateData, action: PayloadAction<any>) {
            state.singleUserSessionQAList = action.payload.data
        },

        clearQAForUser(state: QAStateData, action: PayloadAction<{}>) {
            state.userSessionQAList = []
        },
        setStudentUserSession(state: QAStateData, action: PayloadAction<{ studentUserSessionId: string }>) {
            state.studentUserSessionId = action.payload.studentUserSessionId;
        },

    }
});

export const reducer = slice.reducer;

// ************ api call function to get all the classes ************
export const configUserSessionInfo = (id) : AppThunk => async (dispatch) =>{
    // dispatch(slice.actions.setStudentUserSession({ studentUserSessionId: id }));
    console.log(id,'***ttt***')

}
export const configCourseData = (id) : AppThunk => async (dispatch) =>{
    // dispatch(slice.actions.setStudentUserSession({ studentUserSessionId: id }));
    console.log(id,'***rrr***')

}

export const getDepartmentList = (page: number = 1, limit: number = 50, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/department?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let departmentList: any[] = [];
    let departmentTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let deptList: any[] = [...response?.data[0]];
        departmentTotalCount = response?.data[1] || 0;

        departmentList = deptList.map(element => {
            return {
                label: element?.name || '',
                value: element?.id || null,
                description: element?.description || null,
                createdAt: element?.createdAt || '',
                createdBy: element?.createdBy?.fullName || '',
            }
        });
    }

    if (!searchKey) {
        dispatch(slice.actions.setDepartmentList({ departmentList: departmentList, departmentTotalCount: departmentTotalCount }))
    }

    return { departmentList: departmentList || [], departmentTotalCount: response?.data && response?.data[1] || 0 }

};

// ************ api call function to get the skills list ************

export const getSkillList = (page: number = 1, limit: number = 50, searchKey: string = '', departmentId: string = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/skill?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    dispatch(slice.actions.setSkillList({
        skillList: response?.data && response?.data.length ? response?.data[0] : [],
        skillTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));
};

// ************ api call function to get the topics list ************

export const getTopicList = (page: number = 1, limit: number = 10, searchKey: string = '', skillId: string = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/topic?page=${page}&limit=${limit}&searchKey=${searchKey}&skill=${skillId}`, {}, {});

    dispatch(slice.actions.setTopicList({
        topicList: response?.data && response?.data.length ? response?.data[0] : [],
        topicTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        skillId: skillId,
        clear
    }));
};

// ************ api call function to get the Questions list for a topic ************
export const getQuestionList = (page: number = 1, limit: number = 10, searchKey: string = '', topicId: any = '', clear: boolean,questionType: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/question?page=${page}&limit=${limit}&searchKey=${searchKey}&topic=${topicId}&questionType=${questionType}`, {}, {});
    console.log(response, '~~~~~ getQuestionList')


    
    if(questionType=='MQC'){
        dispatch(slice.actions.setQuestionList({
            questionList: response?.data && response?.data.length ? response?.data[0] : [],
            questionTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
            // publishedQuestionCount:published,
            // topicId: topicId,
            clear
        }));
    }else{
        dispatch(slice.actions.setSubQuestionList({
            questionList: response?.data && response?.data.length ? response?.data[0] : [],
            questionTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
            // publishedQuestionCount:published,
            // topicId: topicId,
            clear
        }));
    }
   
    return response.data;
};

export const getSessionTypeNameList = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session-type?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    let sessionTypeNameList: any[] = [];
    let sessionTypeNameTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionTypeNameTotalCount = response?.data[1] || 0;

        sessionTypeNameList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }

    return { sessionTypeNameList: sessionTypeNameList || [], sessionTypeNameTotalCount: sessionTypeNameTotalCount }
};

export const getSessionTypeListByType = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', type: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session-type?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}&type=${type}`, {}, {});

    let sessionTypeList: any[] = [];
    let sessionTypeTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionTypeTotalCount = response?.data[1] || 0;

        sessionTypeList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }

    return { sessionTypeList: sessionTypeList || [], sessionTypeTotalCount: sessionTypeTotalCount }
};

export const getSessionNameList = (page: number = 1, limit: number = 10, searchKey: string = '', departmentId: any = '', clear: boolean): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session?page=${page}&limit=${limit}&searchKey=${searchKey}&department=${departmentId}`, {}, {});

    let sessionNameList: any[] = [];
    let sessionNameTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        sessionNameTotalCount = response?.data[1] || 0;

        sessionNameList = currentList.map(element => {
            return { label: element?.name || '', value: element?.id || null }
        });
    }

    return { sessionNameList: sessionNameList || [], sessionNameTotalCount: sessionNameTotalCount }
};

export const getQuestionMaster = (): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/question/master`, {}, {});

    dispatch(slice.actions.setQuestionMaster({
        questionTypeMasterList: (response?.questionType && response?.questionType.length) ? response?.questionType : [],
        difficultyLevelMasterList: (response?.difficultyLevel && response?.difficultyLevel.length) ? response?.difficultyLevel : [],
    }));
};

// . api call to get data for the ( / batch / student / sessions) to load dashboard widgets
export const getReports = (date: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/reports?date=${date}`, {}, {});
    // dispatch(slice.actions.setReports(response.data));
    return response;
};

export const getSessionTypeById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/session-type/' + id, {}, {});

    if (response?.data?.id) {
        dispatch(slice.actions.reInitializeSessionTypeQuestionList({
            questionList: (response?.data?.question && response?.data?.question.length) ? response?.data?.question : [],
        }));
    }

    return response?.data?.id ? response?.data : null;
};

export const getSessionById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/session/' + id, {}, {});

    if (response?.data?.id) {
        // dispatch(slice.actions.reInitializeSessionTypeQuestionList({
        //     questionList: (response?.data?.question && response?.data?.question.length) ? response?.data?.question : [],
        // }));
    }

    return response?.data?.id ? response?.data : null;
};

// ************ api call function to add the new class ************

export const createDepartment = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));

        const response: any = await AXIOS_POST('/api/department', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            dispatch(slice.actions.appendNewDepartmentItemIntoDepartmentList({ departmentObject: response?.data }));

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? {
            responseData: {
                label: response?.data?.name || '',
                value: response?.data?.id || null,
                description: response?.data?.description || null,
                createdAt: response?.data?.createdAt || '',
                createdBy: response?.data?.createdBy?.fullName || '',
            },
            status: true
        } : { responseData: null, status: false };

    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

// ************ api call function to add the new skill ************

export const createSkill = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/skill', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            dispatch(slice.actions.appendNewSkillItemIntoSkillList({ skillObject: response?.data }));

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

// ************ api call function to add the new topic within skill ************

export const createTopic = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/topic', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};
export const GetSessionCompleted = (usersessionid): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_GET(`/api/session/get-all-id?userSession=${usersessionid}`, {}, {});
        dispatch(slice.actions.setStudentUserSession({ studentUserSessionId: usersessionid }));
    //   configUserSessionInfo({ studentUserSessionId: usersessionid })
        return response

    } catch (err) {
        return false;
    }
};

export const createQuestion = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/question', payload, {});

        if (response?.data?.id) {
            //  enqueueSnackbar(response?.message, snackbarGlobConfig('success'));
            snackBar(response?.message, snackbarGlobConfig('success'));
            if (payload?.id) {
                dispatch(slice.actions.updateQuestionFromQuestionList({ questionObject: response?.data }));
            }
        } else {
            // enqueueSnackbar(response?.message, snackbarGlobConfig('error'));
            snackBar(response?.message, snackbarGlobConfig('error'));
        }
        let resp = { status: response?.data?.id ? true : false, data: response?.data?.id ? response?.data : null }
        // console.log(resp,"ssssss");

        return resp;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        let resp = { status: false, data: null }

        return resp;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

// ************ api call function to save and update the question list ************

export const createSessionType = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/session-type', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};
// ****** api call function to upload the given file and provide the response as the uploaded files
export const uploadFile = (payload): AppThunk => async (dispatch) => {
    try {
        const response: any = AXIOS_FORM_POST("/api/files/", payload, { "Content-Type": "multipart/form-data" });
        return response;
    } catch (err) {
        return false;
    }
};
// ****** api call function to deletes the given file and provide the response as the uploaded files
export const deleteUploadFile = (payload): AppThunk => async (dispatch) => {
    try {
        const response: any = AXIOS_DELETE(`/api/files/${payload}`, {});
        return response;
    } catch (err) {
        return false;
    }
};

// ************ api call function to save and update the session details ************

export const createSession = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/session', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const deleteDepartmentById = (id: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_DELETE('/api/department/' + id, {});

        if (response?.status == true) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;
    } finally {
        // dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

// ************ api call function to delete the skill ************

export const deleteSkillById = (id: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_DELETE('/api/skill/' + id, {});

        if (response?.status == true) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;
    } finally {
        // dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

// ************ api call function to delete the topic ************
export const deleteTopicById = (id: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_DELETE('/api/topic/' + id, {});

        if (response?.status == true) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;
    } finally {
        // dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const deleteQuestionById = (id: any, snackBar: any): AppThunk => async (dispatch) => {
    try {
        
        const response: any = await AXIOS_DELETE('/api/question/' + id, {});

        if (response?.status == true) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }
        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;
    } finally {
        // dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

/* #region custom functions */
export const setOpenedAccordionSkillId = (id) => (dispatch) => {
    dispatch(slice.actions.setOpenedAccordionSkillId({ openedAccordionSkillId: id ? id : null }));
}

export const setTopicObjForQuesCreation = (topicObject: any = null) => (dispatch) => {
    dispatch(slice.actions.setTopicObjForQuesCreation({ topicObjForQuesCreation: topicObject?.id ? topicObject : null }));
}

export const setQuestionEditable = (questionId: any = null, editableStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setQuestionEditable({ questionId: questionId, editableStatus }));
}
export const setSubjectiveQuestionEditable = (questionId: any = null, editableStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setSubjectiveQuestionEditable({ questionId: questionId, editableStatus }));
}

export const setQuestionExpanded = (questionId: any = null, expandedStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setQuestionExpanded({ questionId: questionId, expandedStatus }));
}

export const setSubjectiveQuestionExpanded = (questionId: any = null, expandedStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setSubjectiveQuestionExpanded({ questionId: questionId, expandedStatus }));
}
export const setUserSessionQuestionExpanded = (questionId: any = null, expandedStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setUserSessionQuestionExpanded({ questionId: questionId, expandedStatus }));
}

export const setAllQuestionExpanded = (expandedStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setAllQuestionExpanded({ expandedStatus }));
}

export const setUserSessionQuestionsExpanded = (expandedStatus: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setUserSessionQuestionsExpanded({ expandedStatus }));
}

export const removeDepartmentFromListById = (id) => (dispatch) => {
    dispatch(slice.actions.removeDepartmentFromListById({ departmentId: id ? id : null }));
}

export const removeSkillFromListById = (id) => (dispatch) => {
    dispatch(slice.actions.removeSkillFromListById({ skillId: id ? id : null }));
}

export const removeQuestionFromListById = (id) => (dispatch) => {
    dispatch(slice.actions.removeQuestionFromListById({ questionId: id ? id : null }));
}

export const removeSubQuestionFromListById = (id) => (dispatch) => {
    dispatch(slice.actions.removeSubQuestionFromListById({ questionId: id ? id : null }));
}

export const removeTopicFromCategorizedTopicListById = (skillId, topicId) => (dispatch) => {
    dispatch(slice.actions.removeTopicFromCategorizedTopicListById({ skillId: skillId || null, topicId: topicId || null }));
}

export const clearSkillList = () => (dispatch) => {
    dispatch(slice.actions.setSkillList({
        skillList: [], skillTotalCount: 0, clear: true
    }));
}
// ************** function to set the question list seide bar popup open status
export const setSessionTypeModalOpenStatus = (status) => (dispatch) => {
    dispatch(slice.actions.setSessionTypeModalOpenStatus({ sessionTypeModalOpenStatus: status == true ? true : false }));
}
export const setSelectedQuestionList = (questionObject: any) => (dispatch) => {
    dispatch(slice.actions.setSelectedQuestionList({ questionObject: questionObject?.id ? questionObject : null }));
}
// ************** this function sets the dashboard widgets reports data to the global variable "reports"
export const setReports = (report: any) => (dispatch) => {
    console.log("intial start", report);

    dispatch(slice.actions.setReports(report))
}

// ************ function called on add click in question to save it to the question list ************

export const setSessionTypeQuestionList = (questionObject: any) => (dispatch) => {
    dispatch(slice.actions.setSessionTypeQuestionList({ questionObject: questionObject?.id ? questionObject : null }));
}

export const clearSessionTypeQuestionList = () => (dispatch) => {
    dispatch(slice.actions.reInitializeSessionTypeQuestionList({ questionList: [] }));
}

export const removeQuestionFromSessionTypeListById = (id) => (dispatch) => {
    dispatch(slice.actions.removeQuestionFromSessionTypeListById({ questionId: id ? id : null }));
}

export const clearQAStore = () => (dispatch) => {
    dispatch(slice.actions.clearQAStore({}));
}

export const clearQAForUser = () => (dispatch) => {
    dispatch(slice.actions.clearQAForUser({}))
}
/* #endregion custom functions */


//get QA For Session User

export const getQAForUser = (sessionId, userId, typeName, typeId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/get-answer?sessionId=${sessionId}&userId=${userId}&sessionTypeId=${typeId}&sessionType=${typeName}`, {}, {});

    if (response?.data) {

        dispatch(slice.actions.setQAForUser({ data: response?.data }))
    }
};

export const getQAForSingleUser = (sessionId, typeName, typeId,userSessionId): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/get-answer?sessionId=${sessionId}&userId=''&sessionTypeId=${typeId}&sessionType=${typeName}&userSessionId=${userSessionId}`, {}, {});
    console.log('getQAForSingleUser getQAForSingleUser ', response)
    if (response?.data) {
        dispatch(slice.actions.setQAForSingleUser({ data: response?.data }))
    }
};

export default slice;