import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST, AXIOS_PUT } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { me } from './auth';
import { GradeStateData } from 'src/types/grade';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: GradeStateData = {
    gradeList: [],
    gradeTotalCount: 0,
    selectedGradeObject: null,
    loader: false,
    deleteRecordById: null,
    isModalOpen: false
};

const slice = createSlice({
    name: 'grade',
    initialState,
    reducers: {
        setGradeList(state: GradeStateData, action: PayloadAction<{ gradeList: any[], gradeTotalCount: number, clear: boolean }>) {
            const currentGradeList = current(state).gradeList;
            state.gradeTotalCount = action.payload.gradeTotalCount;

            if (action.payload.clear) {
                state.gradeList = action.payload.gradeList;
            } else {
                state.gradeList = [...currentGradeList, ...action.payload.gradeList];
            }
        },
        setSelectedGradeObject(state: GradeStateData, action: PayloadAction<{ selectedGradeObject: any }>) {
            state.selectedGradeObject = action.payload.selectedGradeObject
        },
        setModalOpenOrCloseStatus(state: GradeStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: GradeStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordById(state: GradeStateData, action: PayloadAction<{ deleteRecById: string | number }>) {
            state.deleteRecordById = action.payload.deleteRecById
        },
    }
});

export const reducer = slice.reducer;

// API call to get Grade list
export const getGradeList = (clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET('/api/grade', {}, {});
    console.log('getGradeList', response);

    dispatch(slice.actions.setGradeList({
        gradeList: response?.data && response?.data.length ? response?.data : [],
        gradeTotalCount: response?.data && response?.data.length ? response?.data : 0,
        clear
    }));
    dispatch(slice.actions.loader({ loader: false }));
};

// API call to create Grade list
export const createGrade = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/grade', payload, {});
    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        dispatch(slice.actions.setSelectedGradeObject({ selectedGradeObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }

    return response?.data?.id ? true : false;
};

// API call to get particular grade by ID
export const getGradeById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/grade/' + id, {}, {});
    console.log('getGradeById', response);

    dispatch(slice.actions.setSelectedGradeObject({ selectedGradeObject: response?.data.id ? response.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

// API call to Update particular grade by ID
export const updateGradeById = (id, payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_PATCH(`/api/grade/update/${id}`, payload, {});
    console.log('updateGradeById', response);

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        dispatch(slice.actions.setSelectedGradeObject({ selectedGradeObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }
    return response?.data?.id ? true : false;

};

// API call to delete grade by ID
export const deleteGradeById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/grade/delete/' + id, {});
    dispatch(slice.actions.setDeleteRecordById({ deleteRecById: null }));

    if (response) {
        snackBar(response?.message, snackbarGlobConfig('success'));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }
    return response;
};

/* #region custom functions */

// it will set deleteRecordById
export const setDeleteRecordById = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordById({ deleteRecById: id ? id : null }));

    // getGradeList();

}

// it will reset the form after submit
export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedGradeObject({ selectedGradeObject: null }));
}

// it will open/close the modal
export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;