import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_GET, AXIOS_POST } from './service/apiService';
import { EmployeeCourseStateData } from 'src/types/employeeCourse';
import _ from 'lodash';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: EmployeeCourseStateData = {
    courseList: [],
    courseTotalCount: 0,
    selectedCourseObject: null,
    sessionList: [],
    sessionTotalCount: 0,
    loader: false,
    deleteRecId: null,
    tabIndex: 0,
    sessionChipLoader: false,
    selectedUserQuestionObject: null,
    selectedUserQuestionChoice: [],
    creationLoader: false,
    sessionInfo: null,
    courseBatchList: [],
    courseBatchTotalCount: 0,
    userQuestionList: [],
    userQuestionTotalCount: 0,
    selectedUserQuestion: null,
    answerSubmitLoader: false,
    questionPaperListLoader: false,
    questionPaperFlagLoader: false,
    completeTestLoader: false,
    timerPauseStatus: false,
    selectedQRowId: null,
    selectedQRowObject: null,
    counterUsedDuration: 0,
    instructionPopupLoader: false,
    selectedQuestionType: '',
    popupCourseList: [],
    popupCourseTotalCount: 0,
    userSessionData: null,
};

const slice = createSlice({
    name: 'employeeCourse',
    initialState,
    reducers: {
        setPopupCourseList(state: EmployeeCourseStateData, action: PayloadAction<{ courseList: any[], courseTotalCount: number, clear: boolean }>) {
            const currentCourseList: any[] = current(state).courseList;
            state.popupCourseTotalCount = action.payload.courseTotalCount;

            if (action.payload.clear) {
                state.popupCourseList = action.payload.courseList;
            } else {
                state.popupCourseList = [...currentCourseList, ...action.payload.courseList];
            }
        },
        setCourseList(state: EmployeeCourseStateData, action: PayloadAction<{ courseList: any[], courseTotalCount: number, clear: boolean }>) {
            const currentCourseList: any[] = current(state).courseList;
            state.courseTotalCount = action.payload.courseTotalCount;

            if (action.payload.clear) {
                state.courseList = action.payload.courseList;
            } else {
                state.courseList = [...currentCourseList, ...action.payload.courseList];
            }
        },
        setCourseBatchList(state: EmployeeCourseStateData, action: PayloadAction<{ courseBatchList: any[], courseBatchTotalCount: number, clear: boolean }>) {
            const currentBatchList: any[] = current(state).courseBatchList;
            state.courseBatchTotalCount = action.payload.courseBatchTotalCount;

            if (action.payload.clear) {
                state.courseBatchList = action.payload.courseBatchList;
            } else {
                state.courseBatchList = [...currentBatchList, ...action.payload.courseBatchList];
            }
        },
        setSessionList(state: EmployeeCourseStateData, action: PayloadAction<{ sessionList: any[], sessionTotalCount: number, clear: boolean }>) {
            const currentSessionList: any[] = current(state).sessionList;
            state.sessionTotalCount = action.payload.sessionTotalCount;

            if (action.payload.clear) {
                state.sessionList = [...action.payload.sessionList]
            } else {
                state.sessionList = [...currentSessionList, ...action.payload.sessionList];
            }
        },
        setUserSessionInfo(state: EmployeeCourseStateData, action: PayloadAction<{ sessiondetails: any[]}>) {
            // const currentSessionList: any[] = current(state).sessionList;
           
            if (action.payload) {
                state.userSessionData = action.payload.sessiondetails
            } 
        },
        setUserQuestionList(state: EmployeeCourseStateData, action: PayloadAction<{ userQuestionList: any[], userQuestionTotalCount: number, clear: boolean }>) {
            const currentUserQuestionList: any[] = current(state).userQuestionList;
            const incomeUserQuestionList: any[] = [...action.payload.userQuestionList];
            let modifiedUserQuestionList: any[] = incomeUserQuestionList.length && incomeUserQuestionList.map(item => { return { ...item, isSelected: false } }) || [];
            state.userQuestionTotalCount = action.payload.userQuestionTotalCount

            if (action.payload.clear) {
                let orderByUserQuestionList: any[] = _.orderBy(modifiedUserQuestionList, 'id', 'asc');
                state.userQuestionList = [...orderByUserQuestionList];
            } else {
                let combinedUserQuestionList: any[] = [...currentUserQuestionList, ...modifiedUserQuestionList];
                let orderByUserQuestionList: any[] = _.orderBy(combinedUserQuestionList, 'id', 'asc');
                state.userQuestionList = [...orderByUserQuestionList];
            }
        },
        setSelectedUserQuestionObject(state: EmployeeCourseStateData, action: PayloadAction<{ selectedUserQuestionObject: any, selectedUserQuestionChoice: any[], selectedUserSelectedQuestionChoice: any[] }>) {
            state.selectedUserQuestionObject = action.payload.selectedUserQuestionObject;
            let selectedChoice: any[] = [...action.payload.selectedUserSelectedQuestionChoice];
            let userQuestionChoice: any[] = [...action.payload.selectedUserQuestionChoice];
            for (const iterator of userQuestionChoice) {
                let object: any = selectedChoice.filter((obj: any) => (obj.id) == iterator?.id)[0]
                iterator['isCorrect'] = object?.id ? true : false;
            }

            state.selectedUserQuestionChoice = [...userQuestionChoice];
        },
        setFlagStatus(state: EmployeeCourseStateData, action: PayloadAction<{ userAnswerId: any, flagStatus: boolean }>) {
            let selectedObj: any = { ...current(state).selectedUserQuestionObject };
            let nUserquestionList: any[] = [...current(state).userQuestionList];
            selectedObj['flag'] = action.payload.flagStatus == true ? true : false;
            state.selectedUserQuestionObject = { ...selectedObj };

            if (nUserquestionList.length) {
                let nNewQuesList = [];
                for (const iterator of nUserquestionList) {
                    let ntaskI = { ...iterator }

                    if (ntaskI?.userAnswerId == action.payload.userAnswerId) {
                        ntaskI['flag'] = action.payload.flagStatus
                    }

                    nNewQuesList.push(ntaskI);
                }

                state.userQuestionList = [...nNewQuesList];
            }
        },
        setQuestionCompletionStatus(state: EmployeeCourseStateData, action: PayloadAction<{ userObjectId: any, selectedChoiceValue: number }>) {
            const currentUserQuestionList: any[] = current(state).userQuestionList;

            let modifiedUserQuestionArray: any[] = currentUserQuestionList.length && currentUserQuestionList.map(item => {
                return {
                    ...item,
                    selectedChoice: (action.payload.userObjectId == item.id) ? action.payload.selectedChoiceValue : item?.selectedChoice
                }
            }) || [];

            state.userQuestionList = [...modifiedUserQuestionArray];
        },
        setSubjectiveQuestionCompletionStatus(state: EmployeeCourseStateData, action: PayloadAction<{ userObjectId: any, answerValue: string }>) {
            const currentUserQuestionList: any[] = current(state).userQuestionList;

            let modifiedUserQuestionArray: any[] = currentUserQuestionList.length && currentUserQuestionList.map(item => {
                return {
                    ...item,
                    answer: (action.payload.userObjectId == item.id) ? action.payload.answerValue : item?.answer
                }
            }) || [];

            state.userQuestionList = [...modifiedUserQuestionArray];
        },
        setSelectedUserQuestionChoice(state: EmployeeCourseStateData, action: PayloadAction<{ selectedUserQuestionChoice: any[] }>) {
            state.selectedUserQuestionChoice = [...action.payload.selectedUserQuestionChoice]
        },
        setSelectedCourseObject(state: EmployeeCourseStateData, action: PayloadAction<{ selectedCourseObject: any }>) {
            state.selectedCourseObject = action.payload.selectedCourseObject
        },

        setTabIndex(state: EmployeeCourseStateData, action: PayloadAction<{ tabIndex: any }>) {
            state.tabIndex = action.payload.tabIndex
        },
        loader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setSessionChipLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.sessionChipLoader = action.payload.sessionChipLoader
        },
        setCreationLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.creationLoader = action.payload.creationLoader
        },
        setInstructionPopupLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.instructionPopupLoader = action.payload.instructionPopupLoader
        },
        setAnswerSubmitLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.answerSubmitLoader = action.payload.answerSubmitLoader
        },
        setQuestionPaperListLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.questionPaperListLoader = action.payload.questionPaperListLoader
        },
        setQuestionPaperFlagLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.questionPaperFlagLoader = action.payload.questionPaperFlagLoader
        },
        setCompleteTestLoader(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.completeTestLoader = action.payload.completeTestLoader
        },
        setDeleteRecordId(state: EmployeeCourseStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        setSessionInfo(state: EmployeeCourseStateData, action: PayloadAction<{ sessionInfo: any }>) {
            state.sessionInfo = action.payload.sessionInfo
        },
        setTimerPauseStatus(state: EmployeeCourseStateData, action: PayloadAction<any>) {
            state.timerPauseStatus = action.payload.timerPauseStatus
        },
        setSelectedQRow(state: EmployeeCourseStateData, action: PayloadAction<{ selectedQRowId: string | number }>) {
            const currentUserQuestionList: any[] = [...current(state).userQuestionList];

            let modifiedUserQuestionArray: any[] = currentUserQuestionList.length && currentUserQuestionList.map(item => {
                return {
                    ...item,
                    isSelected: (action.payload.selectedQRowId == item.id) ? true : false
                }
            }) || [];

            state.userQuestionList = [...modifiedUserQuestionArray];
        },
        setSelectedQRowObject(state: EmployeeCourseStateData, action: PayloadAction<{ selectedQRowObject: any }>) {
            const currentSelectedRowObject: any = { ...action.payload.selectedQRowObject };

            let repsonseObject: any = {
                quesId: currentSelectedRowObject?.question?.id,
                quesAnswerId: currentSelectedRowObject?.id,
                quesUsedDurationInSecs: currentSelectedRowObject?.usedDurationInSecs || 0,
                quesTotalDurationInSecs: currentSelectedRowObject?.question?.duration ? (currentSelectedRowObject?.question?.duration * 60) : 0,
                quesTotalDurationInMins: currentSelectedRowObject?.question?.duration || 0
            }

            state.selectedQRowObject = { ...repsonseObject };
            state.selectedQRowId = currentSelectedRowObject?.question?.id;
        },
        setCounterUsedDuration(state: EmployeeCourseStateData, action: PayloadAction<{ counterUsedDuration: number }>) {
            state.counterUsedDuration = action.payload.counterUsedDuration;
        },
        setUsedDurationInQuestionList(state: EmployeeCourseStateData, action: PayloadAction<{ userObjectId: string | number, usedDuration: string | number }>) {
            const currentUserQuestionList: any[] = current(state).userQuestionList;

            let modifiedUserQuestionArray: any[] = currentUserQuestionList.length && currentUserQuestionList.map(item => {
                return {
                    ...item,
                    usedDuration: (action.payload.userObjectId == item.id) ? action.payload.usedDuration : item?.usedDuration
                }
            }) || [];

            state.userQuestionList = [...modifiedUserQuestionArray];
        },
        setSelectedQuestionType(state: EmployeeCourseStateData, action: PayloadAction<{ selectedQuestionType: string }>) {
            state.selectedQuestionType = action.payload.selectedQuestionType
        }
    }
});

export const reducer = slice.reducer;

export const getCourseList = (page: number = 1, limit: number = 50, searchKey: string = '', toggleStatus: boolean = false, clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/course/_byUser?page=${page}&limit=${limit}&searchKey=${searchKey}&_all=${toggleStatus}`, {}, {});

    dispatch(slice.actions.setCourseList({
        courseList: response?.data && response?.data.length ? response?.data[0] : [],
        courseTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const getPopupCourseList = (page: number = 1, limit: number = 50, searchKey: string = '', toggleStatus: boolean = false, clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/course/_byUserPopup?page=${page}&limit=${limit}&searchKey=${searchKey}&_all=${toggleStatus}`, {}, {});

    dispatch(slice.actions.setPopupCourseList({
        courseList: response?.data && response?.data.length ? response?.data[0] : [],
        courseTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};
export const getCompletedCourseList = (page: number = 1, limit: number = 50, searchKey: string = '', toggleStatus: boolean = false, clear: boolean = true): AppThunk => async (dispatch) => {
    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    let response: any;
    if (userInfo.id) {
        response = await AXIOS_GET(`/api/course/completedCourseList/${userInfo.id}`, {}, {});
    }
    return response
};
export const getCourseResult = (user_id: string, course_id: string): AppThunk => async (dispatch) => {

    let userInfo = JSON.parse(localStorage.getItem('userDetails'));
    let response: any[] = [];
    if (userInfo.id) {
        response = await AXIOS_GET(`/api/session/all_course_mark?userId=${user_id}&courseId=${course_id}`, {}, {});
    }
    return response
};

export const getStudentLogData = (courseId: number): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/session/studentlog/' + courseId, {}, {});
    return response ? response : null;
};

export const SetCourseCompletionStatus = (payload: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST(`/api/session/statusChange`, payload, {});
    return response ? response : null;
};

export const getCourseById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/course/employee/' + id, {}, {});

    dispatch(slice.actions.setSelectedCourseObject({ selectedCourseObject: response?.data?.id ? response?.data : null }));

    return response?.data?.id ? response?.data?.id : null;
};

export const getSessionListByCourseId = (page: number = 1, limit: number = 50, searchKey: string = '', courseId: any = '', userId?: number, clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/session/_all/by-course?page=${page}&limit=${limit}&searchKey=${searchKey}&course=${courseId}&user=${userId}`, {}, {});

    dispatch(slice.actions.setSessionList({
        sessionList: response?.data && response?.data.length ? response?.data[0] : [],
        sessionTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};
// ********** get user session api call for popup test **********
export const getUserSessionInfo = ( sessionType: string = '',sessionId: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/session/get-user-session?sessionType=${sessionType}&session=${sessionId}`, {}, {});

    dispatch(slice.actions.setUserSessionInfo({
        sessiondetails: response?.data && response?.data.length ? response?.data : [],
        
    }));
    return response;
    dispatch(slice.actions.loader({ loader: false }));
};
// ********** get user Answer id api call for popup test **********
export const getUserAnswerInfo = ( userSessionId: string = '',questionId: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/session/get-user-session-answer?userSession=${userSessionId}&questionId=${questionId}`, {}, {});

    return response;
    // dispatch(slice.actions.loader({ loader: false }));
};
// ********** start session api call for popup test **********
export const startPopupSession = (batchId: string = '', sessionId: string = '', testId: string = '', courseId: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/start-popup-sessions?session=${sessionId}&sessionType=${testId}&action=sessionTypePopup&batchId=${batchId}&courseId=${courseId}`, {}, {});

    return response?.data ? response?.data : null;
};

// ********** end session api call for popup test **********
export const endPopupSession = (popupSessionId: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/end-popup-sessions?popupSessionId=${popupSessionId}`, {}, {});

    return response?.data ? response?.data : null;
};
// **********  api call for question popup trigger
export const triggerQuestionPopup = (popupSessionId: string = '', sessionId: string = '', testId: string = '', questionId: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/session/show-popup?session=${sessionId}&sessionType=${testId}&popupSessionId=${popupSessionId}&questionId=${questionId}`, {}, {});

    return response?.data?.id ? response?.data?.id : null;
};

// ********** api call function to get the sessions data with the study material details to load it in the course reports table
export const getSessionListByCourse = (page: number = 1, limit: number = 50, searchKey: string = '', courseId: any = '', studentId: any = '', clear: boolean = true): AppThunk => async (dispatch) => {
    // dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/session/_all/by-course?page=${page}&limit=${limit}&searchKey=${searchKey}&course=${courseId}&user=${studentId}`, {}, {});
    return response;
};


export const getSessionBySessionIdAndType = (sessionId: any = '', sessionTypeId: any = '', snackBar: any, typeName, qlistType?): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setSessionChipLoader({ sessionChipLoader: true }));

        const sessionLabel = qlistType == 'All' ? 'start-combined-sessions' : qlistType == 'Subjective' ? 'start-subjective-sessions' : 'start-sessions';
        const response: any = await AXIOS_GET(`/api/session/${sessionLabel}?session=${sessionId}&sessionType=${sessionTypeId}&action=${typeName}`, {}, {});
        localStorage.setItem('sessionTypeId', sessionTypeId)

        if (response?.data?.sessionId) {
            // snackBar(response?.message, snackbarGlobConfig('success') );
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.sessionId || '';
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return '';

    } finally {
        dispatch(slice.actions.setSessionChipLoader({ sessionChipLoader: false }));
    }
};

export const answerQuestion = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: true }));
        const response: any = await AXIOS_POST('/api/userAnswers/_answer', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: false }));
    }
};

export const answerSubjectiveQuestion = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: true }));
        const response: any = await AXIOS_POST('/api/userAnswers/subjective_answer', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: false }));
    }
};

export const SubjectiveQuestionDurationUpdate = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: true }));
        const response: any = await AXIOS_POST('/api/userAnswers/_update_subjective_duration', payload, {});
        console.log('-------- SubjectiveQuestionDurationUpdate in service');
        if (response?.data) {
            // snackBar(response?.message, snackbarGlobConfig('success') );
            console.log(response?.data, '--------in service');
            localStorage.setItem('dispatched', 'yes')

        }
        // else {
        //     snackBar(response?.message, snackbarGlobConfig('error' ));
        // }

        return response;
    } catch (err) {
        // snackBar(err, snackbarGlobConfig('error' ));
        // return false;

    } finally {
        // dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: false }));
    }
};

export const getAllSubjectiveQuestionAnswers = (page: number = 1, limit: number = 100, searchKey: string = '', courseId: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    const response: any = await AXIOS_GET(`/api/userAnswers/get_subjective_session?skip=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    return response.data
    // dispatch(slice.actions.setCourseBatchList({
    //     courseBatchList: response?.data && response?.data.length ? response?.data[0] : [],
    //     courseBatchTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
    //     clear
    // }));

    dispatch(slice.actions.loader({ loader: false }));

};

export const getSingleSubjectiveQuestionList = (usersessionId: number): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/userAnswers/get_subjective_answer?userSessionId=${usersessionId}`, {}, {});
    console.log(response, '--------eval');
    return response.data
    // dispatch(slice.actions.setCourseBatchList({
    //     courseBatchList: response?.data && response?.data.length ? response?.data[0] : [],
    //     courseBatchTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
    //     clear
    // }));

    dispatch(slice.actions.loader({ loader: false }));

};

export const submitSubjectiveQuestionsScore = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: true }));
        const response: any = await AXIOS_POST('/api/userAnswers/_update_subjective_mark', payload, {});

        if (response?.data) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setAnswerSubmitLoader({ answerSubmitLoader: false }));
    }
};

export const batchRequestCreate = (payload, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_POST('/api/batch/request/create', payload, {});

        if (response?.data?.id) {
            snackBar(response?.message, snackbarGlobConfig('success'));
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

export const getBatchListByCourseId = (page: number = 1, limit: number = 100, searchKey: string = '', courseId: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/batch/_byCourse?page=${page}&limit=${limit}&searchKey=${searchKey}&course=${courseId}`, {}, {});

    dispatch(slice.actions.setCourseBatchList({
        courseBatchList: response?.data && response?.data.length ? response?.data[0] : [],
        courseBatchTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};
// -*-*-*-*-*-*-*-*-*-*-* connect with popup session from student side  -*-*-*-*-*-*-*-*-*-*-*
export const connectToPopupSession = (testId: string = '', courseId: string = '',sessionId: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));
    
    const response: any = await AXIOS_GET(`/api/session/student-start-popup-sessions?sessionType=${testId}&courseId=${courseId}&action=${'sessionTypePopup'}&session=${sessionId}`, {}, {});
    
    if (response) {
        return response
    } else {
        return null
    }
    // dispatch(slice.actions.setCourseBatchList({
    //     courseBatchList: response?.data && response?.data.length ? response?.data[0] : [],
    //     courseBatchTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
    //     clear
    // }));sdfgh

    dispatch(slice.actions.loader({ loader: false }));
};

export const getUserQuestionList = (userSession: string = '', questionListType: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setQuestionPaperListLoader({ questionPaperListLoader: true }));

        const response: any = await AXIOS_GET(`/api/session/get-user-question-list?userSession=${userSession}&questionListType=${questionListType}`, {}, {});
        let orderByUserQuestionList: any[] = response?.data && response?.data.length ? _.orderBy(response?.data, 'id', 'asc') : [];

        dispatch(slice.actions.setUserQuestionList({
            userQuestionList: [...orderByUserQuestionList],
            userQuestionTotalCount: 0,
            clear: true
        }));

        return orderByUserQuestionList;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setUserQuestionList({ userQuestionList: [], userQuestionTotalCount: 0, clear: true }));
        return [];
    } finally {
        dispatch(slice.actions.setQuestionPaperListLoader({ questionPaperListLoader: false }));
    }
};

export const gePopupQuestionList = (questionListId: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {

        const response: any = await AXIOS_GET(`/api/course/sessionTypeQuestion?sessionType=${questionListId}`, {}, {});
        let orderByUserQuestionList: any[] = response?.data && response?.data.length ? _.orderBy(response?.data, 'id', 'asc') : [];

        return orderByUserQuestionList;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setUserQuestionList({ userQuestionList: [], userQuestionTotalCount: 0, clear: true }));
        return [];
    } finally {
        // dispatch(slice.actions.setQuestionPaperListLoader({ questionPaperListLoader: false }));
    }
};
//*/*/*/*/*/*/*/ get batch list by course */*/*/*/*/*/*/
export const getBatchesByCourse = (courseId: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {

        const response: any = await AXIOS_GET(`/api/course/get/${courseId}`, {}, {});
        let batchList: any[] = response?.data ? response?.data : null;

        return batchList;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        // dispatch(slice.actions.setUserQuestionList({ userQuestionList: [], userQuestionTotalCount: 0, clear: true }));
        return [];
    } finally {
        // dispatch(slice.actions.setQuestionPaperListLoader({ questionPaperListLoader: false }));
    }
};

//*/*/*/*/*/*/*/ get attendees list */*/*/*/*/*/*/
export const getPopupSessionAttendees = (popupSessionId: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {

        const response: any = await AXIOS_GET(`/api/session/popup-session-student-list?popupSessionId=${popupSessionId}`, {}, {});
        let batchList: any[] = response?.data ? response?.data : null;

        return batchList;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        // dispatch(slice.actions.setUserQuestionList({ userQuestionList: [], userQuestionTotalCount: 0, clear: true }));
        return [];
    } finally {
        // dispatch(slice.actions.setQuestionPaperListLoader({ questionPaperListLoader: false }));
    }
};

export const getUserQuestionSingle = (userAnswerId: string = '', questionListType: string = '', snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_GET(`/api/session/get-user-question-single?userAnswerId=${userAnswerId}&questionListType=${questionListType}`, {}, {});

        if (response?.data?.id) {
            // snackBar(response?.message, snackbarGlobConfig('success') );
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        dispatch(slice.actions.setSelectedUserQuestionObject({
            selectedUserQuestionObject: response?.data?.id ? response?.data : null,
            selectedUserQuestionChoice: response?.data?.id ? ((response?.data?.questionChoice && response?.data?.questionChoice.length) ? response?.data?.questionChoice : []) : [],
            selectedUserSelectedQuestionChoice: response?.data?.id ? ((response?.data?.selectedChoice && response?.data?.selectedChoice.length) ? response?.data?.selectedChoice : []) : []
        }));

        return response?.data?.id ? response?.data : null;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        dispatch(slice.actions.setSelectedUserQuestionObject({ selectedUserQuestionObject: null, selectedUserQuestionChoice: [], selectedUserSelectedQuestionChoice: [] }));
        return null;
    } finally {
        dispatch(slice.actions.setSessionChipLoader({ sessionChipLoader: false }));
    }
};

export const updateFlagStatus = (userAnswerId: string = '', flagStatus: boolean = false, snackBar: any): AppThunk => async (dispatch) => {
    try {
        // dispatch(slice.actions.setQuestionPaperFlagLoader({ questionPaperFlagLoader: true }));

        const response: any = await AXIOS_GET(`/api/userAnswers/flag?userAnswerId=${userAnswerId}&flag=${flagStatus}`, {}, {});

        if (response?.status) {
            snackBar(response?.message, snackbarGlobConfig('success'));
            dispatch(slice.actions.setFlagStatus({ userAnswerId: userAnswerId, flagStatus: response?.data == true ? flagStatus : false }));

        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
    } finally {
        // dispatch(slice.actions.setQuestionPaperFlagLoader({ questionPaperFlagLoader: false }));
    }
};

export const updateQuestionDuration = (payload: any = {}, snackBar: any): AppThunk => async (dispatch) => {
    try {
        const response: any = await AXIOS_POST(`/api/userAnswers/_update_duration?userAnswerId`, payload, {});

        if (response?.status) {
            // snackBar(response?.message, snackbarGlobConfig('success') );
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.status;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;
    } finally {
        // dispatch(slice.actions.setQuestionPaperFlagLoader({ questionPaperFlagLoader: false }));
    }
};


export const completeTest = (id, courseId, snackBar: any, qListType: any = null): AppThunk => async (dispatch) => {
    try {
        console.log("id...", localStorage.getItem('sessionTypeId'))

        dispatch(slice.actions.setCompleteTestLoader({ completeTestLoader: true }));
        let response: any;
        let sessionType = localStorage.getItem('sessionTypeId');
        if (qListType && qListType != '' && qListType == 'Subjective') {
            response = await AXIOS_GET(`/api/session/complete-subjective?userSession=${id}&sessionTypeId=${sessionType}`, {}, {});
        }
        else {
            response = await AXIOS_GET(`/api/session/complete-test?userSession=${id}&courseId=${courseId}&sessionTypeId=${sessionType}`, {}, {});
        }

        if (qListType == 'MQC') {
            if (response?.status) {
                snackBar(response?.message, snackbarGlobConfig('success'));
            } else {
                snackBar(response?.message, snackbarGlobConfig('error'));
            }
        }


        return response?.status == true ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCompleteTestLoader({ completeTestLoader: false }));
    }
};

/* #region custom functions */
export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedCourseObject({ selectedCourseObject: null }));
    
}

export const setTabIndex = (index: number) => (dispatch) => {
    dispatch(slice.actions.setTabIndex({ tabIndex: index }));
}

export const setSelectedUserQuestionChoice = (choices: any[] = []) => (dispatch) => {
    dispatch(slice.actions.setSelectedUserQuestionChoice({ selectedUserQuestionChoice: choices.length ? choices : [] }));
}

export const enableTimerPause = (status: boolean = true) => (dispatch) => {
    dispatch(slice.actions.setTimerPauseStatus({ timerPauseStatus: (status) }));
}

export const setSelectedQRow = (questionId: any) => (dispatch) => {
    dispatch(slice.actions.setSelectedQRow({ selectedQRowId: questionId }));
}

export const setSelectedQRowObject = (selectedQRowObject: any) => (dispatch) => {
    dispatch(slice.actions.setSelectedQRowObject({ selectedQRowObject }));
}

export const setUsedDurationInQuestionList = (userObjectId: string | number, usedDuration: string | number) => (dispatch) => {
    dispatch(slice.actions.setUsedDurationInQuestionList({ userObjectId, usedDuration }));
}

export const setCounterUsedDuration = (counterUsedDuration: number) => (dispatch) => {
    dispatch(slice.actions.setCounterUsedDuration({ counterUsedDuration }));
}

export const setQuestionCompletionStatus = (userObjectId: string | number, selectedChoiceValue: number) => (dispatch) => {
    dispatch(slice.actions.setQuestionCompletionStatus({ userObjectId, selectedChoiceValue }));
}
export const setSubjectiveQuestionCompletionStatus = (userObjectId: string | number, answerValue: string) => (dispatch) => {
    dispatch(slice.actions.setSubjectiveQuestionCompletionStatus({ userObjectId, answerValue }));
}

// get session info for header

export const getSessionInfo = (id, snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setCreationLoader({ creationLoader: true }));
        const response: any = await AXIOS_GET('/api/session/sessionInfo/' + id, {}, {});

        if (response?.data?.id) {
            dispatch(slice.actions.setSessionInfo({ sessionInfo: response?.data }));

            // snackBar(response?.message, snackbarGlobConfig('success') );
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? true : false;
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return false;

    } finally {
        dispatch(slice.actions.setCreationLoader({ creationLoader: false }));
    }
};

/* #endregion custom functions */

export const getSessionInstruction = (sessionTypeId: any = '', snackBar: any): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setInstructionPopupLoader({ instructionPopupLoader: true }));
        const response: any = await AXIOS_GET(`/api/session/get-session-instruction?sessionTypeId=${sessionTypeId}`, {}, {});

        if (response?.data?.id) {
            // snackBar(response?.message, snackbarGlobConfig('success') );
        } else {
            snackBar(response?.message, snackbarGlobConfig('error'));
        }

        return response?.data?.id ? { status: true, data: response.data } : { status: false, data: null };
    } catch (err) {
        snackBar(err, snackbarGlobConfig('error'));
        return { status: true, data: null };

    } finally {
        dispatch(slice.actions.setInstructionPopupLoader({ instructionPopupLoader: false }));
    }
};

export const setSelectedQuestionType = (type: string) => (dispatch) => {
    console.log('******* typetypetypetype ', type)
    dispatch(slice.actions.setSelectedQuestionType({ selectedQuestionType: type || null }));
}

export default slice;
