import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { AXIOS_POST } from 'src/slices/service/apiService';
import { Redirect, useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr'
import { snackbarGlobConfig } from 'src/globalConfig';
import { useSnackbar } from 'notistack';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
  generateOtp: (email: string) => Promise<void>;
  forgotpassword: (userName: string, otp: string, newPassword: string, confirmPassword: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null, history, pageRedirection: boolean = false): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    if (pageRedirection) history.push('/app/reports/dashboard');
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
const setSessionLogin = (accessToken: string | null, history, pageRedirection: boolean = false,role?:string): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
     if(role=='Student')console.log('role###', role);
     
    if (pageRedirection && (role.toLowerCase()) != 'student') {
      history.push('/app/reports/dashboard')

    }
    else{history.push('/app/employeecourse');}
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }

    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }

    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  generateOtp: () => Promise.resolve(),
  forgotpassword: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  let response: any = null;

  const login = async (email: string, password: string) => {
    let body: any = {
      "userName": email,
      "password": password
    }

    if(history.location.pathname == '/admin/login'){
      response = await AXIOS_POST('/api/v1/auth/admin/login', body, {});
    } else{
      response = await AXIOS_POST('/api/v1/auth/email/login', body, {});
    }

    const { user, data, token, message } = response;

    if (token) {
      // setSession(token, history, true);
      setSessionLogin(token, history, true,user.role.name);
      dispatch({
        type: 'LOGIN',
        payload: { user }
      });

      localStorage.setItem('userDetails', JSON.stringify(user));

    } else {
      enqueueSnackbar(message || 'Invalid Login', snackbarGlobConfig('error'));
    }

  };

  const logout = () => {
    setSession(null, history, false);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const generateOtp = async (userName: string = '') => {
    let payload: any = {
      "userName": userName
    }

    let response: any = await AXIOS_POST('/api/v1/auth/generate-otp', payload, {});
    if (response?.data == true) {
      history.replace(`/forgotpassword/${userName}`);
      enqueueSnackbar(`${response?.message}`, snackbarGlobConfig('success'));
    } else {
      console.log('response-------->', response)
      // enqueueSnackbar(`${response?.data?.statusCode} - ${response?.data?.message}`, snackbarGlobConfig('error'));
      enqueueSnackbar(`${response?.message}`, snackbarGlobConfig('error'));

    }
  }

  const forgotpassword = async (userName, otp, newPassword, confirmPassword) => {
    let payload: any = {
      "userName": userName,
      "otp": otp,
      "newPassword": newPassword,
      "confirmPassword": confirmPassword
    }

    let response: any = await AXIOS_POST('/api/v1/auth/update-password', payload, {});
    if (response?.data) {
      history.replace(`/login`);
       enqueueSnackbar(`${response?.message}`, snackbarGlobConfig('success'));
      return response.status;
    }
    else{
     enqueueSnackbar(`${response?.message}`, snackbarGlobConfig('error'));
     return response.status;
    }
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, history, false);

          const response = await axios.get<{ user: User; }>('/api/account/me');
          const { user } = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        generateOtp,
        forgotpassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;