/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  Award as AwardIcon
} from 'react-feather';
import NavItem from './NavItem';
import { useDispatch, useSelector } from 'src/store';
import UsersList from '@material-ui/icons/GroupAddOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AssignedCourse from '@material-ui/icons/CollectionsBookmarkOutlined';
import DnsOutlinedIcon from '@material-ui/icons/DnsOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Questionbank from '@material-ui/icons/MenuBookOutlined';
import Approval from '@material-ui/icons/AssignmentTurnedInOutlined';
import SessionEvalution from '@material-ui/icons/InsertChartOutlined';
import CourseReport from '@material-ui/icons/LocalLibraryOutlined';
import GradeConfig from '@material-ui/icons/TuneOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { EventNote } from '@material-ui/icons';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader: string;
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items?.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 60
  },
  desktopDrawer: {
    width: 60,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {

  var navitems = [
    {
      title: 'Dashboard',
      icon: HomeOutlinedIcon,
      href: '/app/reports/dashboard'
    },
    {
      title: 'Question Bank',
      icon: Questionbank,
      href: '/app/questions',
    },
    {
      title: 'User',
      icon: UsersList,
      href: '/app/user',
    },
    {
      title: 'Role',
      icon: AccountTreeOutlinedIcon,
      href: '/app/role',
    },
    {
      title: 'Batch',
      icon: ListAltOutlinedIcon,
      href: '/app/batch'
    },
    {
      title: 'Course',
      icon: ClassOutlinedIcon,
      href: '/app/course'
    },
    {
      title: 'Session',
      href: '/app/session',
      icon: DnsOutlinedIcon
    },
    {
      title: 'Assigned Courses',
      icon: AssignedCourse,
      href: '/app/employeecourse'
    },
    {
      title: 'Joining Request',
      icon: Approval,
      href: '/app/joining-request'
    },
    {
      title: 'Certificate',
      icon: AwardIcon,
      href: '/app/certificate'
    },
    {
      title: 'Session Evalution',
      icon: SessionEvalution,
      href: '/app/statics'
    },
    {
      title: 'Course Reports',
      href: '/app/students-log',
      icon: CourseReport
    },
    {
      title: 'Grade Configuration',
      href: '/app/grade-configuration',
      icon: GradeConfig
    },
    {
      title: 'Trainer Evaluation',
      href: '/app/trainer-evaluation',
      // href: '/app/student-answer',

      icon: PlaylistAddCheckIcon
    },
    {
      title: 'Popup Courses',
      href: '/app/popup-courses',
      // href: '/app/student-answer',

      icon: DynamicFeedIcon
    },
    {
      title: 'Notes',
      href: '/app/notes',

      icon: EventNote
    },

  ]

  var sections: Section[] = [
    {
      subheader: '',
      items: [
      ]
    },
    {
      subheader: '',
      items: []
    }
  ];

  const classes = useStyles();
  const location = useLocation();
  const { defaultListItemsNav, dynamicListItemsNav } = useSelector(state => state.navItems)
  const { user } = useSelector(state => state.auth);

  const setSidebarDefault = () => {
    let list = []
    let data3 = defaultListItemsNav.map(i => i.moduleName)
    for (let i = 0; i < navitems.length; i++) {
      if (data3.includes(navitems[i].title)) {
        list.push(navitems[i])
      }
    }
    list.map(i => { sections[1].items.push(i) })
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const setNavItems = () => {
    setSidebarDefault();
    dynamicListItemsNav.map(i => { sections[1].items.push(i) })
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {
        user && (<PerfectScrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
            >
              <RouterLink to="/">
                <img src='/static/kgisl-logo.png' alt='Kgisl' style={{ height: '2vh' }}></img>
              </RouterLink>
            </Box>
          </Hidden>
          <Box p={2}>
            {
              setNavItems()
            }
            {sections.map((section) => (
              <List
                key={section.subheader}
                subheader={(
                  <ListSubheader
                    disableGutters
                    disableSticky
                  >
                    {section.subheader}
                  </ListSubheader>
                )}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
            ))}
          </Box>
          <Divider />
        </PerfectScrollbar>)
      }

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        {
          user && (
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>
          )
        }

      </Hidden>
      <Hidden mdDown>
        {
          user && (<Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>)
        }

      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
