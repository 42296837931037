import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
        // {
        //   exact: true,
        //   path: '/form/:formUrlSuffix',
        //   component: lazy(() => import('src/views/module/singleFormView'))
        // },
        // {
        //   exact: true,
        //   path: '/form/:formUrlSuffix/:uuid',
        //   component: lazy(() => import('src/views/module/singleFormView'))
        // },
  {
    exact: true,
    path: '/questionpaper',
    component: lazy(() => import('src/views/questionpaper/index'))
  },
  {
    exact: true,
    path: '/questionpaper/:id/:courseId',
    component: lazy(() => import('src/views/questionpaper/index'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/login/:id',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  // {
  //   exact: true,
  //   // guard: GuestGuard,
  //   path: '/admin/login',
  //   component: lazy(() => import('src/views/auth/LoginView'))
  // },
  // {
  //   exact: true,
  //   path: '/login-unprotected',
  //   component: lazy(() => import('src/views/auth/LoginView'))
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/user-registration',
    component: lazy(() => import('src/views/userRegistration/userRegistration'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  // New Forget
  {
    exact: true,
    // guard: GuestGuard,
    path: '/forgotpasswordpage',
    component: lazy(() => import('src/views/auth/new-forgot-pass'))
  },
  // Forgot Password

  {
    exact: true,
    // guard: GuestGuard,
    path: '/forgotpassword',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/forgotpassword/:id',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    path: '/forgotpassword-unprotected',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/questions',
        component: lazy(() => import('src/views/knowledgebase/listView'))
      },

      {
        exact: true,
        path: '/app/knowledgebase/create-kb',
        component: lazy(() => import('src/views/knowledgebase/AccountView'))
      },

      {
        exact: true,
        path: '/app/knowledgebase/create-kb/:id',
        component: lazy(() => import('src/views/knowledgebase/AccountView'))
      },

      {
        exact: true,
        path: '/app/knowledgebase/view/id',
        component: lazy(() => import('src/views/knowledgebase/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/knowledgebase/previewTable',
        component: lazy(() => import('src/views/knowledgebase/AccountView/PreviewTable'))
      },
      // batch
      {
        exact: true,
        path: '/app/batch',
        component: lazy(() => import('src/views/batch/listView'))
      },
      {
        exact: true,
        path: '/app/session',
        component: lazy(() => import('src/views/session'))
      },
      // Study Materaial
      {
        exact: true,
        path: '/app/study-materials',
        component: lazy(() => import('src/views/session/studyMaterialView'))
      },
      // course
      {
        exact: true,
        path: '/app/course',
        component: lazy(() => import('src/views/course'))
      },
      // joining request
      {
        exact: true,
        path: '/app/joining-request',
        component: lazy(() => import('src/views/joiningRequest'))
      },

      // Assigned Courses
      {
        exact: true,
        path: '/app/employeecourse',
        component: lazy(() => import('src/views/employeeCourse'))
      },
      {
        exact: true,
        path: '/app/employeecourse/:id',
        component: lazy(() => import('src/views/employeeCourse'))
      },
      // Certificate
      {
        exact: true,
        path: '/app/certificate',
        component: lazy(() => import('src/views/certificate'))
      },
      // User
      {
        exact: true,
        path: '/app/user',
        component: lazy(() => import('src/views/users/listView'))
      },
      {
        exact: true,
        path: '/app/students-log',
        component: lazy(() => import('src/views/StudentsLogsView'))
      },
      {
        exact: true,
        path: '/app/grade-configuration',
        component: lazy(() => import('src/views/gradeConfig'))
      },
      {
        exact: true,
        path: '/app/user/create-user',
        component: lazy(() => import('src/views/users/UserFormCreationView'))
      },
      {
        exact: true,
        path: '/app/user/create-user/:id',
        component: lazy(() => import('src/views/users/UserFormCreationView'))
      },
      // Roles
      {
        exact: true,
        path: '/app/role',
        component: lazy(() => import('src/views/roles/listView'))
      },
      {
        exact: true,
        path: '/app/role/create-role',
        component: lazy(() => import('src/views/roles/formView'))
      },

      {
        exact: true,
        path: '/app/role/create-role/:id',
        component: lazy(() => import('src/views/roles/formView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/student/evaluation/:id/:typeName/:typeId',
        component: lazy(() => import('src/views/studentEvaluation'))
      },
      {
        exact: true,
        path: '/app/statics',
        component: lazy(() => import('src/views/staticsModule'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView')) 
      },
      {
        exact: true,
        path: '/app/trainer-evaluation',
        component: lazy(() => import('src/views/trainerEvalution'))
      },
      {
        exact: true,
        path: '/app/student-answer',
        component: lazy(() => import('src/views/trainerEvalution/answerPage'))
      },
    
       // popup Courses
       {
        exact: true,
        path: '/app/popup-courses',
        component: lazy(() => import('src/views/popupCourses'))
      },
      {
        exact: true,
        path: '/app/popup-courses/:id',
        component: lazy(() => import('src/views/popupCourses'))
      },
      {
        exact: true,
        path: '/app/popup-questions/:id',
        component: lazy(() => import('src/views/popupQuestions/questionsList'))
      },
      {
        exact: true,
        path: '/app/popup-test',
        component: lazy(() => import('src/views/popupSessionView/popupTest'))
      },
      // Notes
      {
        exact: true,
        path: '/app/notes',
        component: lazy(() => import('src/views/notes/listView'))
      },
      {
        exact: true,
        path: '/app/notes/create-notes',
        component: lazy(() => import('src/views/notes/notes-form'))
      },
      {
        exact: true,
        path: '/app/notes/create-notes/:id',
        component: lazy(() => import('src/views/notes/notes-form'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
