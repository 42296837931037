import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { RoleStateData } from 'src/types/roles';
import {toastr} from 'react-redux-toastr';

const initialState: RoleStateData = {
    roleList: [],
    page: 1,
    limit: 5,
    totalCount: 0,
    selectedRoleObject: null,
    loader: false,
    deleteRecId: null
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getRolesList(state: RoleStateData, action: PayloadAction<{ roleList: any[], totalCount: number }>) {
            state.roleList = action.payload.roleList;
            state.totalCount = action.payload.totalCount;
        },
        getRoleSingleData(state: RoleStateData, action: PayloadAction<{ selectedRoleObject: any }>) {
            state.selectedRoleObject = action.payload.selectedRoleObject;      
        },
        loader(state: RoleStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: RoleStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
        deleteListRecordFromStateById(state: RoleStateData, action: PayloadAction<{ roleList: any[], deleteRecId: string | number }>) {
            let tempList: any[] = action.payload.roleList || [];
            let finalPayloadList: any[] = tempList.length ? tempList.filter(item => item?.id != action.payload.deleteRecId) : [...tempList];
            state.roleList = finalPayloadList;
            state.totalCount = finalPayloadList.length;  
        }
    }
});
 
export const reducer = slice.reducer;

export const getAllRoles = (page: number = 0, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/role?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    if (response) {
        dispatch(slice.actions.getRolesList({ roleList: response?.data || [], totalCount: response?.totalCount || 0 }))
    }

    dispatch(slice.actions.loader({ loader: false }));
};

export const saveRole = (payload, history): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/role', payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        history.replace(`/app/role/create-role/${response?.data.id}`);
        dispatch(slice.actions.getRoleSingleData({ selectedRoleObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const updateRole = (id, payload): AppThunk => async (dispatch) => {
    
    const response: any = await AXIOS_PATCH(`/api/role/${id}`, payload, {});

    if (response?.data?.id) {
        toastr.success('Success', response?.message);
        dispatch(slice.actions.getRoleSingleData({ selectedRoleObject: response?.data }))
    } else {
        toastr.error('Error', response?.message);
    }
};

export const getRoleById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/role/' + id, {}, {});

    if (response) {
        dispatch(slice.actions.getRoleSingleData({ selectedRoleObject: response.data }))
    }
};

export const deleteRoleById = (roleList, id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/role/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.data?.isDeleted) {
        toastr.success('Success', response?.message);
        // dispatch(slice.actions.deleteListRecordFromStateById({ roleList: roleList, deleteRecId: id }));
    } else {
        toastr.error('Error', response?.message);
    }

    return response?.data?.isDeleted || false;
};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.getRoleSingleData({ selectedRoleObject: null }))
}
/* #endregion custom functions */


export default slice;
