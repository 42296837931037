import { createSlice, current } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import { AXIOS_DELETE, AXIOS_GET, AXIOS_PATCH, AXIOS_POST } from './service/apiService';
import { toastr } from 'react-redux-toastr';
import { me } from './auth';
import { BatchStateData } from 'src/types/batch';
import { snackbarGlobConfig } from 'src/globalConfig';

const initialState: BatchStateData = {
    batchList: [],
    batchTotalCount: 0,
    selectedBatchObject: null,
    loader: false,
    deleteRecId: null,
    isModalOpen: false
};

const slice = createSlice({
    name: 'batchs',
    initialState,
    reducers: {
        setBatchList(state: BatchStateData, action: PayloadAction<{ batchList: any[], batchTotalCount: number, clear: boolean }>) {
            const currentBatchList = current(state).batchList;
            state.batchTotalCount = action.payload.batchTotalCount;

            if (action.payload.clear) {
                state.batchList = action.payload.batchList;
            } else {
                state.batchList = [...currentBatchList, ...action.payload.batchList];
            }
        },

        setSelectedBatchObject(state: BatchStateData, action: PayloadAction<{ selectedBatchObject: any }>) {
            state.selectedBatchObject = action.payload.selectedBatchObject
        },
        setModalOpenOrCloseStatus(state: BatchStateData, action: PayloadAction<{ isModalOpen: boolean }>) {
            state.isModalOpen = action.payload.isModalOpen
        },
        loader(state: BatchStateData, action: PayloadAction<any>) {
            state.loader = action.payload.loader
        },
        setDeleteRecordId(state: BatchStateData, action: PayloadAction<{ deleteRecId: string | number }>) {
            state.deleteRecId = action.payload.deleteRecId
        },
    }
});

export const reducer = slice.reducer;

export const getBatchList = (page: number = 0, limit: number = 5, searchKey: string = '', clear: boolean = true): AppThunk => async (dispatch) => {
    dispatch(slice.actions.loader({ loader: true }));

    const response: any = await AXIOS_GET(`/api/batch?page=${page + 1}&limit=${limit}&searchKey=${searchKey}`, {}, {});
    console.log('getBatchList', response);

    dispatch(slice.actions.setBatchList({
        batchList: response?.data && response?.data.length ? response?.data[0] : [],
        batchTotalCount: response?.data && response?.data.length ? response?.data[1] : 0,
        clear
    }));

    dispatch(slice.actions.loader({ loader: false }));
};

export const getUserList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/users?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let userList: any[] = [];
    let userTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data];
        userTotalCount = response?.totalCount || 0;

        userList = currentList.map(element => {
            return { label: `${element?.firstName} ${element?.lastName} (${element?.userName || ''})`, value: element?.id || null }
        });
    }

    return { userList: userList || [], userTotalCount: userTotalCount }
};

export const getCourseList = (page: number = 1, limit: number = 10, searchKey: string = ''): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET(`/api/course?page=${page}&limit=${limit}&searchKey=${searchKey}`, {}, {});

    let courseList: any[] = [];
    let courseTotalCount: number = 0;

    if (response?.data && response?.data.length) {
        let currentList: any[] = [...response?.data[0]];
        courseTotalCount = response?.data[1] || 0;

        courseList = currentList.map(element => {
            return { label: element?.courseName || '', value: element?.id || null }
        });
    }

    return { courseList: courseList || [], courseTotalCount: courseTotalCount }
};

export const createBatch = (payload: any, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_POST('/api/batch', payload, {});

    if (response?.data?.id) {
        snackBar(response?.message, snackbarGlobConfig('success'));
        dispatch(slice.actions.setSelectedBatchObject({ selectedBatchObject: null }));
        dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: false }));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }

    return response?.data?.id ? response?.data?.id : 'failed';
};

export const uploadFileForBatch = (payload): AppThunk => async (dispatch) => {
    try {
        const response: any = AXIOS_POST("/api/batch/upload", payload, {});
        return response;
    } catch (err) {
        return false;
    }
};

export const getBatchFiles = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/files/batch-files?batchId=' + id, {}, {});
    
    return response

};

export const deleteBatchFiles = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/files/' + id, {});
    
    return response

};

export const getBatchById = (id): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_GET('/api/batch/' + id, {}, {});

    dispatch(slice.actions.setSelectedBatchObject({ selectedBatchObject: response?.data?.id ? response?.data : null }));
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen: true }));

};

export const deleteBatchById = (id, snackBar: any): AppThunk => async (dispatch) => {
    const response: any = await AXIOS_DELETE('/api/batch/' + id, {});
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: null }));

    if (response?.status) {
        snackBar(response?.message, snackbarGlobConfig('success'));
    } else {
        snackBar(response?.message, snackbarGlobConfig('error'));
    }

    return response?.status == true ? true : false;

};

/* #region custom functions */
export const setDeleteRecordId = (id) => (dispatch) => {
    dispatch(slice.actions.setDeleteRecordId({ deleteRecId: id ? id : null }));
}

export const resetEntireForm = () => (dispatch) => {
    dispatch(slice.actions.setSelectedBatchObject({ selectedBatchObject: null }));
}

export const setModalOpenOrCloseStatus = (isModalOpen: boolean = false) => (dispatch) => {
    dispatch(slice.actions.setModalOpenOrCloseStatus({ isModalOpen }));
}
/* #endregion custom functions */

export default slice;
